export const zh = {
  sign_in: '登入',
  email: '電子郵件',
  password: '密碼',
  i_agree_to_toc: '我同意條款和條件。',
  terms_and_conditions: '條款和條件',
  privacy_policy: '隱私政策',
  already_have_an_account: '已有帳戶？',
  sign_up: '註冊',
  dont_have_an_account: '還沒有帳戶？',
  forgot_password: '忘記密碼？',
  email_already_in_use: '電子郵件已被使用',
  weak_password: '密碼太弱',
  too_many_attempts: '嘗試次數過多',
  invalid_email_or_password: '無效的電子郵件或密碼',
  user_already_registered: '用戶已註冊',
  unexpected_error: '意外錯誤',
  audios: '音訊',
  name: '名稱',
  auto: '自動',
  auto_detect: '自動偵測',
  speaker_num: '演講者數量',
  speaker_num_reason: '如果您告訴我們演講者的數量，我們將提供更好的轉錄結果。',
  audio_language: '音訊語言',
  your_email: '您的電子郵件',
  email_result: '我們將向您發送一封帶有轉錄結果的電子郵件。',
  provide_language: '如果您告訴我們音訊的語言，我們將提供更好的轉錄結果。',
  upload_audio: '上傳音訊',
  upload_file: '上傳檔案',
  process_audio: '處理您的檔案',
  process_audio_des: '請稍等，音訊正在進行轉錄。這可能需要幾分鐘的時間。',
  uploading: '上傳中',
  pending: '等待中',
  uploaded: '已上傳',
  failed: '失敗',
  status: '狀態',
  language: '語言',
  speakers: '演講者',
  duration: '時長',
  done: '完成',
  close: '關閉',
  queuing: '排隊中',
  created_at: '建立於',
  records: '記錄',
  transcribe: '轉錄',
  delete: '刪除',
  processing: '處理中',
  from_youtube: '來自 YouTube',
  upload_files: '上傳檔案',
  place_youtube_link_here: '在這裡放置 YouTube 連結',
  submit: '提交',
  email_notification:
    '轉錄時間取決於音訊檔案的長度和複雜性。通常，您可以在幾個小時內收到轉錄結果的電子郵件通知。',
  filters: '篩選器',
  filter: '篩選',
  replace: '替換',
  share_and_export: '分享和匯出',
  replace_all: '全部替換',
  next: '下一個',
  previous: '上一個',
  find: '尋找',
  replace_with: '替換為',
  x_of_x: '{current} / {total}',
  scroll_lock: '鎖定滾動',
  tags: '標籤',
  bookmark: '書籤',
  audio: '音訊',
  cancel: '取消',
  export: '匯出',
  export_format: '匯出格式',
  copy_text: '複製文字',
  monologue: '獨白',
  include_timestamps: '包含時間戳記',
  include_speaker_names: '包含演講者姓名',
  combine_same_speaker: '合併相同演講者片段',
  export_mode: '匯出模式',
  no_preview: '無預覽',
  copy: '複製',
  copied: '已複製',
  unlock_pro_feature: '要解鎖此功能，請升級到「Pro」訂閱方案。',
  pro_features: 'Pro 功能',
  pro_minutes: '1800 分鐘 / 月',
  pro_interact_with_audio: '與音訊互動',
  pro_interact_with_audio_des: '向音訊提問',
  pro_summary: 'AI 摘要',
  pro_summary_des: '摘要您的音訊',
  pro_priority: '優先佇列',
  pro_priority_des: '優先處理音訊',
  pro_multi_speakers: '多演講者辨識',
  pro_multi_speakers_des: '準確檢測並標記不同的演講者',
  redirect_to_stripe: '您將被重定向到 Stripe 以完成付款。',
  x_per_month: '{x} / 月',
  x_per_year: '{x} / 年',
  trash: '垃圾桶',
  dashboard: '儀表板',
  logout: '登出',
  are_you_sure_to_delete: '您確定要刪除選定的記錄嗎？',
  restore: '還原',
  are_you_sure_to_restore: '您確定要還原選定的記錄嗎？',
  deleted_at: '刪除於',
  hidden_segment: '在 {y} 分鐘後，{x} 個片段被隱藏。',
  settings: '設定',
  subscription: '訂閱',
  default_transcription_language: '預設轉錄語言',
  contact_us: '聯絡我們',
  security: '安全',
  about_us: '關於我們',
  download_app: '下載應用程式',
  download: '下載',
  app_store: 'App Store',
  play_store: 'Play 商店',
  upload_voice: '上傳語音',
  no_audio_found: '找不到音訊',
  please_input_valid_email_first: '請先輸入有效的電子郵件',
  handling_audio: '我們正在處理您的音訊。請稍候片刻。',
  welcome_to_leave: '歡迎離開此頁面，我們將向您發送帶有轉錄結果的電子郵件。',
  send_your_result: '我們將向您發送帶有轉錄結果的電子郵件。',
  transcribe_audio_thank:
    '感謝您使用我們的轉錄服務！我們正在努力為您轉錄音訊，並感謝您的耐心等待。',
  transcribe_audio_no_stay:
    '您的轉錄可能需要一些時間，但不用擔心，您不需要留在此頁面。一旦結果準備好，我們將通過電子郵件向您發送結果。',
  date: '日期',
  sign_up_to_access_more_functions: '註冊以使用更多功能',
  you_can_use_functions: '登入後，您可以使用以下功能',
  know_more: '了解更多',
  modify_transcription: '修改文本轉錄',
  play_audio: '播放音訊',
  content_filters: '內容篩選器',
  export_diff_type: '匯出不同類型的檔案',
  screenshots: '螢幕截圖',
  free_trial: '免費試用',
  pricing: '價格',
  faq: '常見問題',
  contact: '聯絡',
  login: '登入',
  register: '註冊',
  please_login_before_purchase: '請在購買之前登入',
  no_records_found: '未找到記錄',
  x_minutes: '{x} 分鐘',
  confirm: '確認',
  notification_mobile_app: '手機應用通知',
  notification_mobile_app_des: '當轉錄結果準備好時，接收手機應用程式通知',
  notification_email: '電子郵件通知',
  notification_email_des: '當轉錄結果準備好時，接收電子郵件通知',
  video: '影片',
  or: '或',
  sign_in_with_google: '使用 Google 帳號登入',
  sign_in_with_apple: '使用 Apple 帳號登入',
  login_method: '登入方式',
  delete_account: '刪除帳號',
  delete_account_confirm_3_time: '按刪除按鈕 3 次確認',
  popup_blocked: '阻擋彈出式視窗',
  account_info: '帳戶資訊',
  redeem: '兌換',
  redeem_code: '兌換碼',
  summary_des1: '摘要服務提供轉錄結果的摘要。它可以將轉錄結果摘要成幾個句子。',
  summary_des2: '您的舊摘要將被新摘要取代。',
  consume_transcription_quota: '消耗轉錄配額',
  summary: '摘要',
  query: '查詢',
  mins_per_query: '每個查詢{x}分鐘',
  information_not_found: '未找到資訊',
  permission_deny: '權限拒絕',
  please_login_to_check_the_result: '請登入以查看結果',
  audio_length: '音頻長度',
  delete_permanently: '永久刪除',
  are_you_sure_to_delete_permanently: '您確定要永久刪除嗎？',
  delete_audios_move_to_bin:
    '被刪除的紀錄將被移至垃圾桶。垃圾桶中的所有紀錄將在30天後被刪除。',
  payment_id: '付款 ID',
  current_plan: '當前計畫',
  plan_name: '計畫名稱',
  expire_date: '到期日期',
  subscription_method: '訂閱方法',
  payment_method: '付款方式',
  payment_history: '付款歷史',
  amount: '金額',
  invoice: '發票',
  receipt: '收據',
  link: '連結',
  edit: '編輯',
  back: '返回',
  return: '返回',
  cancel_subscription: '取消訂閱',
  cancel_subscription_google_play:
    '在您的 Android 設備上 > 轉到 Google Play 商店 > 帳戶 > 付款與訂閱 > 訂閱 > SoundType AI > 取消訂閱',
  cancel_subscription_apple_store:
    '在您的 iOS 設備上 > 轉到設定 > [您的名字] > 訂閱 > SoundType AI > 取消訂閱',
  delete_account_des: '刪除帳戶將永久刪除您的所有資料。',
  delete_account_subscription: '如果您有訂閱，請先取消您的訂閱。',
  keep_subscription: '保持訂閱',
  cancel_subscription_feature1: '錄音',
  cancel_subscription_feature1_des: '您將僅能查看錄音的前 {x} 分鐘。',
  cancel_subscription_feature2: '轉錄時間',
  cancel_subscription_feature2_des: '您每月僅能轉錄 {x} 分鐘。',
  cancel_subscription_count: '請按下按鈕 {x} 次以確認',
  next_payment_date: '下一個付款日期',
  basic_plan: '基本計畫',
  resume_subscription: '恢復訂閱',
  active: '活躍',
  expired: '已過期',
  auto_renewal_clause: '自動續訂條款',
  auto_renewal_payment_web:
    '您的訂閱將自動續訂，除非您在當前期間結束前至少 24 小時取消。您的帳戶將在當前期間結束前 24 小時內收取續訂費用。您可以通過前往「設定」>「帳單」來管理和取消您的訂閱。',
  auto_renewal_terms_and_conditions:
    '訂閱即表示您同意我們的條款和條件以及隱私政策。',
  payment: '支付',
  monthly: '每月',
  annual: '每年',
  minutes_per_month: '{x} 分鐘/月',
  buy_now: '立即購買',
  yearly: '年付',
  yearly_save: '最高省下 {x}%',
  pro_plan: '專業版',
  business_plan: '商業版',
  enterprise_plan: '企業版',
  everything_in_basic: '基礎版的所有功能，外加',
  everything_in_pro: '專業版的所有功能，外加',
  everything_in_business: '商業版的所有功能，外加',
  ai_chat_x: 'AI 聊天 x {x}',
  ai_summary_x: 'AI 摘要 x {x}',
  max_x_minutes_per_transcription: '每次轉錄最多 {x} 分鐘',
  basic_des2: '識別講者',
  basic_des3: '音頻/視頻文件轉錄',
  basic_des4: '審核與編輯轉錄文稿',
  pro_des2: '優先隊列',
  pro_des3: '以 SRT 格式導出',
  pro_des4: '無限制 AI 摘要',
  business_des1: '使用分析報告',
  business_des2: '與團隊成員一起的協作工作空間',
  business_des3: '在團隊內分享和編輯轉錄文稿。',
  business_des4: '控制分享權限',
  coming_soon: '即將推出',
  get_started: '開始使用',
  bill_annually_x: '每年支付 {x}',
  no_credit_card_required: '無需信用卡',
  save_x: '節省 {x}%',
  price_per_month: ' / 月',
  not_available: '不可用',
  pricing_title: '價格',
  upgrade: '升級',
  ask_question_to_your_audio: '向您的音訊提問',
  monthly_minutes: '每月分鐘數',
  payment_interval: '付款間隔',
  annually: '每年',
  billing: '帳單',
  subscription_plan: '訂閱計劃',
  please_cancel_your_current_subscription_first: '請先取消您當前的訂閱',
  please_cancel_your_current_subscription_first_des:
    '要更改訂閱計劃，您需要先取消當前訂閱。',
  cancel_subscription_instruction: '前往設定 > 帳單 > 取消訂閱',
  website_version: '網站版本',
  change_to_annual_plan: '更改為年度計劃',
  update_annual_mobile_platform_reminder: '提醒',
  update_annual_mobile_platform_reminder_des:
    '請注意，您需要在 {x} 上取消當前訂閱，然後才能更改為年度計劃。否則，您將被雙重收費。',
  i_understand: '我了解',
  no_credit: '沒有足夠的分鐘',
  re_submit: '重新提交',
  loading_audio: '加載音頻中',
  your_password: '你的密碼',
  home: '首頁',
  title_a_z: '標題 A-Z',
  title_z_a: '標題 Z-A',
  older_first: '較舊的優先',
  newest_first: '最新的優先',
  table_item_count: '{x} - {y} 共 {z} 項',
  starred: '已加星號',
  search: '搜索',
  create_folder: '創建文件夾',
  folder_name: '文件夾名稱',
  create: '創建',
  export_preview: '匯出預覽',
  combine_segments: '合併段落',
  export_audio_only: '僅當音頻正在轉錄時，您可以匯出音頻。',
  transcribe_remaining_x_minutes: '轉錄剩餘 {x} 分鐘',
  x_minutes_remain_be_transcribed: '剩餘 {x} 分鐘待轉錄',
  upgrade_plan_to_transcribe: '升級您的方案以轉錄完整音頻',
  only_paid_user_can_use: '僅付費用戶可轉錄剩餘分鐘',
  converting_to_audio: '正在轉換為音頻',
  move_to_folder: '移至資料夾',
  move_audios_to_folder: '將選定的音訊移至資料夾',
  please_select_folder: '請選擇一個資料夾',
  empty_folder: '空資料夾',
  move: '移動',
  user_not_found:
    '找不到使用者。無法找到指定的使用者。請確認詳細資訊並重試，或聯絡支援以獲得進一步協助。',
  invalid_link: '無效連結',
  translation: '翻譯',
  translate: '翻譯',
  translate_des1:
    '翻譯服務提供轉錄結果的AI翻譯。它可以將轉錄結果翻譯成另一種語言。',
  translate_des2: '翻譯後的文本暫時不可編輯。',
  translate_des3: '您的舊翻譯將被新翻譯取代。',
  translating: '翻譯中',
  translating_des: '翻譯過程正在進行中。請稍等片刻。翻譯準備好後我們會通知您。',
  please_select_language: '請選擇語言',
  display_language: '顯示語言',
  both: '兩者',
  original: '原文',
  translated: '翻譯',
  default_payment_currency_x_set:
    '您的付款貨幣設定為 {x}。如果您想更改，請聯繫我們：{email}。',
  invalid_link_des:
    'YouTube 連結無效。不支援直播影片、播放清單、私人內容、年齡限制或區域限制的內容。',
  free_member_limited_view: '基礎方案用戶只能訪問前 {x} 分鐘的轉錄結果。',
  free_user_service_once: '基礎方案用戶僅可使用此服務一次。',
  free_user_summary:
    '基礎方案用戶僅可使用此服務一次。請升級至 Pro 方案以匯總更多音訊。',
  free_user_query:
    '基礎方案用戶僅可使用此服務一次。請升級至 Pro 方案以提出更多查詢。',
  cancel_subscription_des:
    '您的訂閱將於當前計費週期結束時 ({x}) 取消。在此日期之後，您將降級為基礎方案，且無法再使用 Pro 方案的功能：',
  free_member_limited_transcribe_view:
    '僅前 {x} 分鐘的內容將被轉錄並供基礎方案用戶查看。',
  reset_password_email_sent:
    '請檢查您的電子郵件。如果該帳戶存在，將會發送密碼重置郵件。',
  m_downloads: '100萬+ 下載',
  provider: '提供者',
  size: '大小',
  category: '類別',
  productivity: '生產力',
  languages: '語言',
  languages_90: '90+ 種語言',
  app_des:
    '使用 SoundType AI 輕鬆將語音轉換為文字！我們的尖端應用將語音錄音和音訊檔案轉換為高度準確的文字轉錄。不論是會議、訪談、播客還是視頻內容，SoundType AI 都能提供精確性與效率。<br><br>主要功能：<br>- 高級講者識別：輕鬆區分個別講者。<br>- AI 驅動的摘要：生成簡潔且深入的轉錄摘要。<br>- 全球語言支持：輕鬆轉錄超過 90 種語言的對話。<br>- 增強的音訊功能：專為專業人士、創作者及企業設計。<br><br>告別繁瑣的手動轉錄，使用 SoundType AI 採用更智能、更快速的方式將音訊轉為文字。',
  click_or_drop_file: '點擊或拖放音訊 / 視頻文件',
  remain_x_y_minutes: '剩餘 {x}/{y} 分鐘',
  my_account: '我的帳戶',
}
