import { User } from '@/api_client/types/user'

export const deserializeUser = (apiUser): User => {
  if (apiUser == null) {
    return null
  }
  return {
    id: apiUser.id,
    email: apiUser.email,
    is_anonymous: apiUser.is_anonymous,
    display_language: apiUser.display_language,
    audio_language: apiUser.audio_language,
    subscription: apiUser.subscription,
    access_token: apiUser.access_token,
    notification_email: apiUser.notification_email,
    notification_mobile_app: apiUser.notification_mobile_app,
    credits: apiUser.credits,
    max_credits: apiUser.max_credits,
    providers: apiUser.providers,
    stripe_customer_id: apiUser.stripe_customer_id,
    stripe_default_currency: apiUser.stripe_default_currency,
  }
}
