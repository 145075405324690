import Http from '../Http'
import { IProductsResult } from '../interfaces/Product'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'

export default class Purchases extends Http {
  public async list(token: string, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.purchasesPath(),
      token,
      params,
    )) as any
  }

  public async stripeCancel(token: string, id): Promise<IProductsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.purchaseStripeCancel(id),
      token,
      {},
    )) as IProductsResult
  }

  public async stripeResume(token: string, id): Promise<IProductsResult> {
    return (await this.spreeResponse(
      'get',
      Routes.purchaseStripeResume(id),
      token,
      {},
    )) as IProductsResult
  }

  public async stripeCheckout(token: string, data): Promise<IProductsResult> {
    return (await this.spreeResponse(
      'post',
      Routes.purchaseStripeCheckoutPath(),
      token,
      {},
      data,
    )) as IProductsResult
  }

  public async getStripeDefaultCurrency(token: string): Promise<any> {
    return await this.spreeResponse(
      'get',
      Routes.purchaseStripeDefaultCurrencyPath(),
      token,
      {},
    )
  }

  public async redeemCode(token: string, code): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.purchaseRedeemPath(),
      token,
      {},
      { redeem_code: code },
    )) as IProductsResult
  }
}
