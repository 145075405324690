export const it = {
  sign_in: 'Accedi',
  email: 'Email',
  password: 'Password',
  i_agree_to_toc: 'Accetto i termini e le condizioni.',
  terms_and_conditions: 'Termini e condizioni',
  privacy_policy: 'Informativa sulla privacy',
  already_have_an_account: 'Hai già un account?',
  sign_up: 'Registrati',
  dont_have_an_account: 'Non hai un account?',
  forgot_password: 'Password dimenticata?',
  email_already_in_use: 'Email già in uso',
  weak_password: 'Password debole',
  too_many_attempts: 'Troppi tentativi',
  invalid_email_or_password: 'Email o password non valide',
  user_already_registered: 'Utente già registrato',
  unexpected_error: 'Errore imprevisto',
  audios: 'Audio',
  name: 'Nome',
  auto: 'Auto',
  auto_detect: 'Rilevamento automatico',
  speaker_num: 'Numero di altoparlanti',
  speaker_num_reason:
    'Se ci indichi il numero di altoparlanti, otterremo un risultato di trascrizione migliore.',
  audio_language: 'Lingua audio',
  your_email: 'La tua email',
  email_result: "Ti invieremo un'email con il risultato della trascrizione.",
  provide_language:
    "Se ci indichi la lingua dell'audio, otterremo un risultato di trascrizione migliore.",
  upload_audio: 'Carica audio',
  upload_file: 'Carica file',
  process_audio: 'Elaborazione del file in corso',
  process_audio_des:
    'Attendi mentre il tuo audio viene trascritto. Potrebbe richiedere alcuni minuti.',
  uploading: 'Caricamento in corso',
  pending: 'In attesa',
  uploaded: 'Caricato',
  failed: 'Fallito',
  status: 'Stato',
  language: 'Lingua',
  speakers: 'Altoparlanti',
  duration: 'Durata',
  done: 'Fatto',
  close: 'Chiudi',
  queuing: 'In coda',
  created_at: 'Creato il',
  records: 'Registrazioni',
  transcribe: 'Trascrivi',
  delete: 'Elimina',
  processing: 'In elaborazione',
  from_youtube: 'Da Youtube',
  upload_files: 'Carica file',
  place_youtube_link_here: 'Inserisci qui il link di Youtube',
  submit: 'Invia',
  email_notification:
    'Il tempo di completamento dipende dalla durata e dalla complessità del file audio. Di solito puoi aspettarti di ricevere la trascrizione entro poche ore. Ti avviseremo tramite email non appena sarà pronta.',
  filters: 'Filtri',
  filter: 'Filtro',
  replace: 'Sostituisci',
  share_and_export: 'Condividi ed esporta',
  replace_all: 'Sostituisci tutto',
  next: 'Avanti',
  previous: 'Precedente',
  find: 'Trova',
  replace_with: 'Sostituisci con',
  x_of_x: '{current} di {total}',
  scroll_lock: 'Blocco scorrimento',
  tags: 'Tag',
  bookmark: 'Segnalibro',
  audio: 'Audio',
  cancel: 'Annulla',
  export: 'Esporta',
  export_format: 'Formato di esportazione',
  copy_text: 'Copia testo',
  monologue: 'Monologo',
  include_timestamps: 'Includi timestamp',
  include_speaker_names: 'Includi nomi degli altoparlanti',
  combine_same_speaker: 'Combina segmenti dello stesso altoparlante',
  export_mode: 'Modalità di esportazione',
  no_preview: 'Nessuna anteprima',
  copy: 'Copia',
  copied: 'Copiato',
  unlock_pro_feature:
    "Per sbloccare questa funzione, passa al piano di abbonamento 'Pro'.",
  pro_features: 'Funzionalità Pro',
  pro_minutes: '1800 minuti / mese',
  pro_interact_with_audio: "Interagisci con l'audio",
  pro_interact_with_audio_des: 'Fai domande al tuo audio',
  pro_summary: 'Sommario AI',
  pro_summary_des: 'Sintetizza il tuo audio',
  pro_priority: 'Coda prioritaria',
  pro_priority_des: "Elabora l'audio con priorità",
  pro_multi_speakers: 'Riconoscimento multi-altoparlanti',
  pro_multi_speakers_des:
    'Rileva e etichetta con precisione diversi altoparlanti',
  redirect_to_stripe:
    'Verrai reindirizzato a Stripe per completare il pagamento.',
  x_per_month: '{x} / Mese',
  x_per_year: '{x} / Anno',
  trash: 'Cestino',
  dashboard: 'Dashboard',
  logout: 'Logout',
  are_you_sure_to_delete:
    'Sei sicuro di voler eliminare le registrazioni selezionate?',
  restore: 'Ripristina',
  are_you_sure_to_restore:
    'Sei sicuro di voler ripristinare le registrazioni selezionate?',
  deleted_at: 'Eliminato il',
  hidden_segment: '{x} segmenti sono nascosti dopo {y} minuti.',
  settings: 'Impostazioni',
  subscription: 'Abbonamento',
  default_transcription_language: 'Lingua predefinita per la trascrizione',
  contact_us: 'Contattaci',
  security: 'Sicurezza',
  about_us: 'Chi siamo',
  download_app: "Scarica l'app",
  download: 'Scarica',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Carica la tua voce',
  no_audio_found: 'Nessun audio trovato',
  please_input_valid_email_first: "Inserisci prima un'email valida",
  handling_audio: 'Stiamo trascrivendo il tuo audio. Attendere un momento.',
  welcome_to_leave:
    "Puoi lasciare questa pagina. Ti invieremo un'email con il risultato della trascrizione.",
  send_your_result:
    "Ti invieremo un'email con il risultato della trascrizione.",
  transcribe_audio_thank:
    'Grazie per aver utilizzato il nostro servizio di trascrizione! Stiamo lavorando sodo per trascrivere il tuo audio e apprezziamo la tua pazienza.',
  transcribe_audio_no_stay:
    "La trascrizione potrebbe richiedere un po' di tempo, ma non preoccuparti, non devi rimanere su questa pagina. Ti invieremo via email i risultati non appena saranno pronti.",
  date: 'Data',
  sign_up_to_access_more_functions: 'Registrati per accedere a più funzioni',
  you_can_use_functions:
    "Puoi utilizzare le seguenti funzioni dopo aver effettuato l'accesso",
  know_more: 'Scopri di più',
  modify_transcription: 'Modifica la trascrizione del testo',
  play_audio: 'Riproduci audio',
  content_filters: 'Filtri di contenuto',
  export_diff_type: 'Esporta diversi tipi di file',
  screenshots: 'Screenshot',
  free_trial: 'Prova gratuita',
  pricing: 'Prezzi',
  faq: 'Domande frequenti',
  contact: 'Contatto',
  login: 'Accedi',
  register: 'Registrati',
  please_login_before_purchase: "Effettua l'accesso prima dell'acquisto",
  no_records_found: 'Nessun record trovato',
  x_minutes: '{x} Minuti',
  confirm: 'Confermare',
  notification_mobile_app: "Notifica dell'applicazione mobile",
  notification_mobile_app_des:
    "Ricevi una notifica sull'app mobile quando il risultato della trascrizione è pronto",
  notification_email: 'Notifica via email',
  notification_email_des:
    'Ricevi una notifica via email quando il risultato della trascrizione è pronto',
  video: 'Video',
  or: 'O',
  sign_in_with_google: 'Accedi con Google',
  sign_in_with_apple: 'Accedi con Apple',
  login_method: 'Metodo di accesso',
  delete_account: 'Elimina account',
  delete_account_confirm_3_time:
    'Premi il pulsante di eliminazione 3 volte per confermare',
  popup_blocked: 'Popup bloccato',
  account_info: 'Informazioni account',
  redeem: 'Riscatta',
  redeem_code: 'Riscatta codice',
  summary_des1:
    'Il servizio di riepilogo fornisce un riepilogo del risultato della trascrizione. Può riassumere il risultato della trascrizione in poche frasi.',
  summary_des2: 'Il tuo vecchio riepilogo sarà sostituito dal nuovo riepilogo.',
  consume_transcription_quota: 'Consuma il credito di trascrizione',
  summary: 'Riepilogo',
  query: 'Query',
  mins_per_query: '{x} minuti per query',
  information_not_found: 'Informazione non trovata',
  permission_deny: 'Permesso negato',
  please_login_to_check_the_result:
    'Per favore, accedi per controllare il risultato',
  audio_length: "Durata dell'audio",
  delete_permanently: 'Elimina definitivamente',
  are_you_sure_to_delete_permanently:
    'Sei sicuro di voler eliminare definitivamente?',
  delete_audios_move_to_bin:
    'I record eliminati saranno spostati nel cestino. Tutti i record nel cestino verranno eliminati dopo 30 giorni.',
  payment_id: 'ID pagamento',
  current_plan: 'Piano attuale',
  plan_name: 'Nome piano',
  expire_date: 'Data di scadenza',
  subscription_method: 'Metodo di sottoscrizione',
  payment_method: 'Metodo di pagamento',
  payment_history: 'Storia dei pagamenti',
  amount: 'Importo',
  invoice: 'Fattura',
  receipt: 'Ricevuta',
  link: 'Collegamento',
  edit: 'Modifica',
  back: 'Indietro',
  return: 'Ritorno',
  cancel_subscription: 'Annulla abbonamento',
  cancel_subscription_google_play:
    'Sul tuo dispositivo Android > Vai su Google Play Store > Account > Pagamenti e abbonamenti > Abbonamenti > SoundType AI > Annulla abbonamento',
  cancel_subscription_apple_store:
    'Sul tuo dispositivo iOS > Vai su Impostazioni > [il tuo nome] > Abbonamenti > SoundType AI > Annulla abbonamento',
  delete_account_des:
    'Eliminare l’account cancellerà tutti i tuoi dati in modo permanente.',
  delete_account_subscription:
    'Se hai un abbonamento, per favore annulla il tuo abbonamento prima.',
  keep_subscription: 'Mantieni Abbonamento',
  cancel_subscription_feature1: 'Registrazioni',
  cancel_subscription_feature1_des:
    'Potrai visualizzare solo i primi {x} minuti delle tue registrazioni.',
  cancel_subscription_feature2: 'Tempo di trascrizione',
  cancel_subscription_feature2_des:
    'Potrai trascrivere solo {x} minuti al mese.',
  cancel_subscription_count:
    'Si prega di premere il pulsante {x} volte per confermare',
  next_payment_date: 'Data del prossimo pagamento',
  basic_plan: 'Piano Base',
  resume_subscription: 'Riprendi abbonamento',
  active: 'Attivo',
  expired: 'Scaduto',
  auto_renewal_clause: 'Clausola di rinnovo automatico',
  auto_renewal_payment_web:
    'Il tuo abbonamento verrà rinnovato automaticamente a meno che tu non lo annulli almeno 24 ore prima della fine del periodo corrente. Il tuo account verrà addebitato per il rinnovo entro 24 ore prima della fine del periodo corrente. Puoi gestire e annullare i tuoi abbonamenti andando su "Impostazioni" > "Fatturazione".',
  auto_renewal_terms_and_conditions:
    "Abbonandoti, accetti i nostri Termini e Condizioni e l'Informativa sulla Privacy.",
  payment: 'Pagamento',
  monthly: 'Mensile',
  annual: 'Annuale',
  minutes_per_month: '{x} minuti/mese',
  buy_now: 'Acquista Ora',
  yearly: 'Annuale',
  yearly_save: 'Risparmia fino a {x}%',
  pro_plan: 'Pro',
  business_plan: 'Business',
  enterprise_plan: 'Impresa',
  everything_in_basic: 'Tutto in Basic, più',
  everything_in_pro: 'Tutto in Pro, più',
  everything_in_business: 'Tutto in Business, più',
  ai_chat_x: 'Chat IA x {x}',
  ai_summary_x: 'Riassunto IA x {x}',
  max_x_minutes_per_transcription: 'Max {x} minuti per trascrizione',
  basic_des2: 'Identificazione del relatore',
  basic_des3: 'Trascrizione file audio/video',
  basic_des4: 'Rivedi e modifica le trascrizioni',
  pro_des2: 'Coda Prioritaria',
  pro_des3: 'Esporta in formato SRT',
  pro_des4: 'Riassunto IA illimitato',
  business_des1: 'Rapporto analisi utilizzo',
  business_des2: 'Spazio di lavoro collaborativo con i membri del team',
  business_des3: 'Condividi e modifica le trascrizioni con il tuo team.',
  business_des4: 'Controlla le autorizzazioni di condivisione',
  coming_soon: 'Prossimamente',
  get_started: 'Inizia',
  bill_annually_x: 'Fatturato {x} annualmente',
  no_credit_card_required: 'Nessuna carta di credito richiesta',
  save_x: 'Risparmia {x}%',
  price_per_month: ' / mese',
  not_available: 'Non disponibile',
  pricing_title: 'Prezzi',
  upgrade: 'Aggiorna',
  ask_question_to_your_audio: 'Fai una domanda al tuo audio',
  monthly_minutes: 'Minuti mensili',
  payment_interval: 'Intervallo di pagamento',
  annually: 'Annualmente',
  billing: 'Fatturazione',
  subscription_plan: 'Piano',
  please_cancel_your_current_subscription_first:
    'Annulla prima il tuo abbonamento corrente',
  please_cancel_your_current_subscription_first_des:
    'Per cambiare il tuo piano di abbonamento, devi annullare prima il tuo abbonamento corrente.',
  cancel_subscription_instruction:
    'Vai su Impostazioni > Fatturazione > Annulla Abbonamento',
  website_version: 'Versione del sito',
  change_to_annual_plan: 'Passa al piano annuale',
  update_annual_mobile_platform_reminder: 'Promemoria',
  update_annual_mobile_platform_reminder_des:
    'Si prega di notare che è necessario annullare il proprio abbonamento su {x} prima di passare al piano annuale. Altrimenti, verrà addebitato due volte.',
  i_understand: 'Ho capito',
  no_credit: 'Non abbastanza minuti',
  re_submit: 'Invia di nuovo',
  loading_audio: 'Caricamento audio',
  your_password: 'La tua password',
  home: 'Home',
  title_a_z: 'Titolo A-Z',
  title_z_a: 'Titolo Z-A',
  older_first: 'Più vecchio prima',
  newest_first: 'Più recente prima',
  table_item_count: '{x} - {y} di {z} elementi',
  starred: 'Stellato',
  search: 'Cerca',
  create_folder: 'Crea cartella',
  folder_name: 'Nome cartella',
  create: 'Crea',
  export_preview: 'Esporta anteprima',
  combine_segments: 'Combina segmenti',
  export_audio_only:
    "Puoi esportare solo l'audio quando l'audio è in fase di trascrizione.",
  transcribe_remaining_x_minutes: 'Trascrivi i rimanenti {x} minuti',
  x_minutes_remain_be_transcribed: 'Rimangono {x} minuti da trascrivere',
  upgrade_plan_to_transcribe:
    "Aggiorna il tuo piano per trascrivere l'audio completo",
  only_paid_user_can_use:
    'Solo gli utenti paganti possono trascrivere i minuti rimanenti',
  converting_to_audio: 'Conversione in audio',
  move_to_folder: 'Sposta nella cartella',
  move_audios_to_folder: 'Sposta gli audio selezionati nella cartella',
  please_select_folder: 'Si prega di selezionare una cartella',
  empty_folder: 'Cartella vuota',
  move: 'Sposta',
  user_not_found:
    "Utente non trovato. L'utente specificato non è stato trovato. Verifica i dettagli e riprova, oppure contatta l'assistenza per ulteriore supporto.",
  invalid_link: 'Link non valido',
  translation: 'Traduzione',
  translate: 'Traduci',
  translate_des1:
    "Il servizio di traduzione fornisce una traduzione AI del risultato della trascrizione. Può tradurre il risultato della trascrizione in un'altra lingua.",
  translate_des2: 'Il testo tradotto non è modificabile in questo momento.',
  translate_des3:
    'La tua vecchia traduzione verrà sostituita dalla nuova traduzione.',
  translating: 'Traduzione in corso',
  translating_des:
    'Il processo di traduzione è in corso. Attendere un momento. Ti avviseremo quando la traduzione sarà pronta.',
  please_select_language: 'Seleziona una lingua',
  display_language: 'Lingua di visualizzazione',
  both: 'Entrambi',
  original: 'Originale',
  translated: 'Tradotto',
  default_payment_currency_x_set:
    'La tua valuta di pagamento è impostata su {x}. Se desideri modificarla, contattaci a {email} .',
  invalid_link_des:
    'Il link di YouTube non è valido. Video in diretta, playlist, contenuti privati, con restrizioni di età o bloccati per regione non sono supportati.',
  free_member_limited_view:
    'Gli utenti del piano base avranno accesso solo ai primi {x} minuti del risultato della trascrizione.',
  free_user_service_once:
    'Gli utenti del piano base possono utilizzare questo servizio solo una volta.',
  free_user_summary:
    'Gli utenti del piano base possono utilizzare questo servizio solo una volta. Effettua l’upgrade al piano Pro per riassumere più audio.',
  free_user_query:
    'Gli utenti del piano base possono utilizzare questo servizio solo una volta. Effettua l’upgrade al piano Pro per porre più domande.',
  cancel_subscription_des:
    'Il tuo abbonamento sarà annullato alla fine del ciclo di fatturazione corrente ({x}). Dopo tale data, sarai retrocesso al piano base e non potrai più accedere alle funzionalità del piano Pro:',
  free_member_limited_transcribe_view:
    'Solo i primi {x} minuti saranno trascritti e visibili per gli utenti del piano base.',
  reset_password_email_sent:
    'Controlla la tua email. Se questo account esiste, verrà inviata un’email per reimpostare la password.',
  m_downloads: '1M+ Download',
  provider: 'Fornitore',
  size: 'Dimensione',
  category: 'Categoria',
  productivity: 'Produttività',
  languages: 'Lingue',
  languages_90: '90+ lingue',
  app_des:
    'Trasforma le parole pronunciate in testo senza sforzo con SoundType AI! La nostra app all’avanguardia converte registrazioni vocali e file audio in trascrizioni basate su testo altamente accurate. Che tu stia lavorando con riunioni, interviste, podcast o contenuti video, SoundType AI offre precisione ed efficienza.<br><br>Caratteristiche principali:<br>- Identificazione avanzata degli interlocutori: Distingui facilmente i singoli interlocutori.<br>- Riepiloghi basati su IA: Genera riepiloghi concisi e approfonditi delle tue trascrizioni.<br>- Supporto linguistico globale: Trascrivi conversazioni in oltre 90 lingue con facilità.<br>- Funzionalità audio avanzate: Progettato per professionisti, creatori e aziende.<br><br>Dì addio alla trascrizione manuale faticosa e adotta un modo più intelligente e veloce per trasformare l’audio in testo con SoundType AI.',
  click_or_drop_file: 'Clicca o trascina audio / video',
  remain_x_y_minutes: 'Rimangono {x}/{y} minuti',
  my_account: 'Il mio account',
}
