<template>
  <div>
    <div v-if="isAnonymousMode" style="margin-bottom: 12px">
      <CTooltip :content="$t('email_result')">
        <template #toggler="{ on }">
          <span v-on="on" class="title-input">
            <label>{{ $t('your_email') }}*</label>
            <InformationOutlineIcon :size="20"></InformationOutlineIcon>
          </span>
        </template>
      </CTooltip>
      <CFormInput
        v-model="email"
        :placeholder="$t('email')"
        class="email-input"
      />
    </div>
    <CTooltip :content="$t('provide_language')">
      <template #toggler="{ on }">
        <span v-on="on" class="title-input">
          <label>{{ $t('audio_language') }}*</label>
          <InformationOutlineIcon :size="20"></InformationOutlineIcon>
        </span>
      </template>
    </CTooltip>
    <LanguageSelect v-model="language" />
    <CTooltip :content="$t('speaker_num_reason')">
      <template #toggler="{ on }">
        <span v-on="on" class="title-input">
          <label>{{ $t('speaker_num') }}</label>
          <InformationOutlineIcon :size="20"></InformationOutlineIcon>
        </span>
      </template>
    </CTooltip>
    <v-select
      v-model="speaker_num"
      :options="speakers_array"
      class="input"
      :clearable="false"
    >
      <template v-slot:option="option">
        {{ option.label == 0 ? $t('auto_detect') : option.label }}
      </template>
      <template v-slot:selected-option="option">
        {{ option.label == 0 ? $t('auto_detect') : option.label }}
      </template>
    </v-select>
    <!--    <hr />-->
    <!--    <div-->
    <!--      v-if="isShowEmail && !isValidEmail()"-->
    <!--      style="margin: 40px; text-align: center"-->
    <!--    >-->
    <!--      {{ $t('please_input_valid_email_first') }}-->
    <!--    </div>-->
    <div style="margin-top: 24px">
      <div style="margin-bottom: 24px">
        <CFormCheck
          :button="{
            color: mode === 'upload' ? 'dark' : 'light',
            shape: 'rounded-pill',
            style: 'margin-right:8px',
          }"
          type="radio"
          name="options"
          id="upload"
          autocomplete="off"
          :checked="mode == 'upload'"
          :label="$t('upload_files')"
          @update:modelValue="(_) => (mode = 'upload')"
        />
        <label style="width: 4px" />
        <CFormCheck
          :button="{
            color: mode !== 'upload' ? 'dark' : 'light',
            shape: 'rounded-pill',
          }"
          type="radio"
          name="options"
          id="youtube"
          autocomplete="off"
          :checked="mode == 'youtube'"
          :label="$t('from_youtube')"
          @update:modelValue="(_) => (mode = 'youtube')"
        />
      </div>
      <div v-if="mode == 'youtube'" style="margin-bottom: 8px">
        <!--          <div>{{ $t('from_youtube') }}</div>-->
        <div>
          <CFormInput
            type="url"
            id="youtube_link"
            class="youtube_link"
            v-model="youtube_link"
            :disabled="is_sending_youtube"
            @change="(v) => (youtube_error = '')"
            :placeholder="$t('place_youtube_link_here')"
          />
          <CButton
            :disabled="youtube_link == '' || is_sending_youtube"
            color="info"
            shape="rounded-pill"
            style="width: 100%; margin-top: 8px; color: white"
            v-if="!isAnonymousMode"
            @click="submitYoutube"
            >{{ $t('submit') }}
          </CButton>
        </div>
        <div v-if="youtube_error != ''" style="color: red; font-size: 13px">
          {{ $t(youtube_error) }}
        </div>
        <div v-if="is_sending_youtube">
          <div style="margin-top: 20px; text-align: center">
            <CSpinner />
          </div>
        </div>
      </div>
      <div v-else>
        <!--          <div>{{ $t('upload') }}</div>-->
        <div class="dropbox">
          <input
            type="file"
            @change="onFileChange"
            accept="audio/*"
            class="input-file"
            :multiple="multiple"
          />

          <div
            v-if="isAnonymousMode && this.files.length > 0"
            style="width: 100%; text-align: center"
          >
            <div style="font-size: 15px; font-weight: bold; color: dodgerblue">
              {{ files[0].file.name }}
            </div>
            <div style="font-size: 13px; font-weight: 400">
              {{ formattedDuration(audioDurationSeconds) }}
            </div>
            <CButton
              style="position: absolute; top: 12px; right: 0; z-index: 2"
              @click="clearFiles"
            >
              <CloseIcon />
            </CButton>
          </div>
          <div v-else class="dropbox-init-message">
            <img :src="uploadIcon" alt="upload" />
            {{ $t('click_or_drop_file') }}
          </div>
        </div>
      </div>

      <!--      <div-->
      <!--        v-if="!isPaidUser"-->
      <!--        style="-->
      <!--          text-align: right;-->
      <!--          font-size: 13px;-->
      <!--          padding-left: 40px;-->
      <!--          line-height: 15px;-->
      <!--        "-->
      <!--      >-->
      <!--        {{ $t('free_member_limited_view', { x: 8 }) }}-->
      <!--      </div>-->
      <div
        v-if="errorMessage != ''"
        style="color: red; font-size: 13px; margin-top: 8px"
      >
        {{ $t(errorMessage) }}
      </div>
      <CButton
        v-if="isAnonymousMode"
        style="width: 100%; margin-top: 16px; margin-bottom: 8px"
        shape="rounded-pill"
        color="primary"
        @click="submitAnonymousAudio"
        :disabled="isUploading || is_sending_youtube"
      >
        <!--        !isValidEmail() ||-->
        <!--        language == '' ||-->
        <!--        (mode === 'upload' && files.length == 0) ||-->
        <!--        (mode === 'youtube' && youtube_link == '')-->
        {{ $t('submit') }}
      </CButton>
      <div
        v-else-if="files.length != 0"
        style="margin-top: 28px; margin-bottom: 28px"
      >
        <div v-if="isMobile">
          <div class="row" style="font-weight: bold">
            <div class="col-12">
              {{ $t('records') }}
            </div>
          </div>
          <div
            v-for="(file, index) in files"
            :key="file.name + index"
            class="row"
          >
            <div class="col-12">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  border-top: 1px solid lightgrey;
                  padding-top: 8px;
                  margin-top: 8px;
                "
              >
                <div>
                  {{ file.name }}
                </div>
                <div>
                  <CProgress
                    v-if="file.status === 'uploading'"
                    :value="file.process"
                    color="info"
                  >
                    {{ file.process }}%
                  </CProgress>
                  <CBadge :color="getStatusColor(file.status)" v-else>
                    {{ this.getStatus(file.status) }}
                  </CBadge>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                  {{ $t('language') }}:
                  {{ $t(SupportedLanguages.getLanguageByValue(file.language)) }}
                </div>
                <div>
                  {{ $t('speakers') }}:
                  {{ file.speakers == 0 ? $t('auto') : file.speakers }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <!--  Uploaded files -->
          <div class="row" style="font-weight: bold">
            <div class="col-6">
              {{ $t('name') }}
            </div>
            <div class="col-2">
              {{ $t('language') }}
            </div>
            <div class="col-2">
              {{ $t('speakers') }}
            </div>
            <div class="col-2">
              {{ $t('status') }}
            </div>
          </div>
          <div
            v-for="(file, index) in files"
            :key="file.name + index"
            class="row"
            style="margin-bottom: 4px"
          >
            <div class="col-6">
              {{ file.name }}
            </div>
            <div class="col-2">
              {{ $t(SupportedLanguages.getLanguageByValue(file.language)) }}
            </div>
            <div class="col-2">
              {{ file.speakers == 0 ? $t('auto') : file.speakers }}
            </div>
            <div class="col-2">
              <CProgress
                v-if="file.status === 'uploading'"
                :value="file.process"
                color="info"
              >
                {{ file.process }}%
              </CProgress>
              <CBadge :color="getStatusColor(file.status)" v-else>
                {{ this.getStatus(file.status) }}
              </CBadge>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SupportedLanguages from '@/helpers/supportedLanguages'
import { cilCloudUpload, cilInfo } from '@coreui/icons'
import { AuthHelper } from '@/api_client'
import { getAuth, signInAnonymously, deleteUser } from 'firebase/auth'
import { logEvent } from 'firebase/analytics'
import uploadIcon from '@/assets/icons/upload.svg'
import CloseIcon from 'vue-material-design-icons/Close.vue'

import InformationOutlineIcon from 'vue-material-design-icons/InformationOutline.vue'
import axios from 'axios'
import LanguageSelect from '@/components/LanguageSelect.vue'

export default {
  name: 'UploadAudios',
  components: {
    LanguageSelect,
    InformationOutlineIcon,
    CloseIcon,
  },
  emits: ['uploading', 'uploaded'],
  props: {
    default_mode: {
      type: String,
      default: 'upload',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    isAnonymousMode: {
      type: Boolean,
      default: false,
    },
    folder_id: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      cilCloudUpload,
      cilInfo,
    }
  },
  data() {
    const speakers_array = []
    for (let i = 0; i < 11; i++) {
      speakers_array.push(i)
    }

    const tmp_langs = []
    for (let i = 0; i < SupportedLanguages.LANGUAGES.length; i++) {
      if (SupportedLanguages.LANGUAGES[i].value === '') {
        // don't use auto
        // tmp_langs.push({ text: this.$t('auto_detect'), value: '' })
      } else {
        tmp_langs.push(SupportedLanguages.LANGUAGES[i])
      }
    }
    return {
      uploadIcon,
      auth: getAuth(),
      email: '',
      mode: this.default_mode,
      is_sending_youtube: false,
      youtube_link: '',
      youtube_error: '',
      SupportedLanguages,
      language: AuthHelper.getAudioLangCode(),
      speaker_num: 0,
      speakers_array,
      uploadingFileId: null,
      isUploading: false,
      languages: tmp_langs,
      files: [],
      audioDurationSeconds: 0, // for Anonymous mode
      anonymousProcess: 0, // for Anonymous mode
      isPaidUser: AuthHelper.isPaidUser(),
      errorMessage: '',
    }
  },
  watch: {
    // watch email and language
    email: {
      handler: function (val) {
        this.clearErrorMessage()
      },
    },
    language: {
      handler: function (val) {
        this.clearErrorMessage()
      },
    },
    files: {
      handler: function (val) {
        this.clearErrorMessage()
      },
    },
  },
  computed: {
    isMobile() {
      return screen.width <= 760
    },
  },
  methods: {
    clearFiles() {
      this.files = []
      console.log('clearFiles')
    },
    // langSearch(options, search) {
    //   return options.filter((option) =>
    //     [option.text, option.en_text].some((text) =>
    //       text.toLowerCase().includes(search.toLowerCase()),
    //     ),
    //   )
    // },
    extractVideoId(url) {
      const regExp =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
      const match = url.match(regExp)
      return match ? match[1] : null
    },
    firebaseEvent(eventName, eventParams = {}) {
      logEvent(this.analytics, eventName, eventParams)
    },
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email)
    },
    // reset_data() {
    //   this.mode = 'upload'
    //   this.is_sending_youtube = false
    //   this.youtube_link = ''
    //   this.youtube_error = ''
    //   this.language = AuthHelper.getAudioLangCode()
    //   this.speaker_num = 0
    //   this.uploadingFileId = null
    //   this.languages = SupportedLanguages.LANGUAGES
    //   this.files = []
    // },
    async submitYoutube() {
      this.youtube_error = ''
      if (this.is_sending_youtube) return
      var regex = /^https:/i
      const youtube_id = this.extractVideoId(this.youtube_link)
      const { title, duration } = await this.fetchVideoMetadata(youtube_id)
      if (
        this.youtube_link == '' ||
        !regex.test(this.youtube_link) ||
        youtube_id == null
      ) {
        this.youtube_error = 'invalid_link_des'
        return
      }

      this.is_sending_youtube = true
      this.$emit('uploading')
      this.firebaseEvent('wa_youtube')
      this.apiClient
        .addAudio(
          this.language,
          this.speaker_num,
          this.email,
          null,
          this.youtube_link,
          this.isAnonymousMode,
          title,
          duration,
          this.folder_id,
        )
        .then((data) => {
          this.$emit('uploaded', data.uuid)
          this.files.push({
            index: this.files.length,
            file: null,
            name: data.name,
            language: data.language,
            speakers: data.speakers,
            status: 'uploaded',
            process: 0,
          })
          this.youtube_link = ''
        })
        .catch((error) => {
          console.log(error)
          this.youtube_error = 'invalid_link_des'
        })
        .finally(() => {
          this.is_sending_youtube = false
        })
    },
    async fetchVideoMetadata(videoId) {
      const key = 'AIzaSyB7wQWfxs3qi-KivGwR7c8YlD4H4KAp7TY'
      const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet,contentDetails&key=${key}`

      try {
        const response = await axios.get(url)
        const videoData = response.data.items[0]

        // Extract title and duration
        const videoTitle = videoData.snippet.title
        const videoDuration = this.convertISO8601Duration(
          videoData.contentDetails.duration,
        )

        return { title: videoTitle, duration: videoDuration }
      } catch (error) {
        console.error('Error fetching video metadata:', error)
      }
      return { title: null, duration: null }
    },
    convertISO8601Duration(duration) {
      // Example: PT1H2M10S => duration in seconds
      let totalSeconds = 0
      const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/)

      if (match[1]) totalSeconds += parseInt(match[1]) * 3600 // hours to seconds
      if (match[2]) totalSeconds += parseInt(match[2]) * 60 // minutes to seconds
      if (match[3]) totalSeconds += parseInt(match[3]) // seconds

      return totalSeconds
    },
    getStatus(status) {
      switch (status) {
        case 'pending_upload':
          return this.$t('pending')
        case 'uploading':
          return this.$t('uploading')
        case 'uploaded':
          return this.$t('uploaded')
        case 'failed':
          return this.$t('failed')
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'uploaded':
          return 'success'
        default:
          return 'dark'
      }
    },
    formattedDuration(duration) {
      if (duration === null) return null
      const minutes = Math.floor(duration / 60)
        .toString()
        .padStart(2, '0')
      const seconds = Math.floor(duration % 60)
        .toString()
        .padStart(2, '0')
      return `${minutes}:${seconds}`
    },
    onFileChange(event) {
      if (event.target.files.length === 0) {
        this.files = []
        return
      }
      if (this.isAnonymousMode) {
        this.files = []
        const file = event.target.files[0]
        if (file) {
          const url = URL.createObjectURL(file)
          const mediaElement = document.createElement(
            file.type.startsWith('audio') ? 'audio' : 'video',
          )
          mediaElement.src = url

          mediaElement.addEventListener('loadedmetadata', () => {
            this.audioDurationSeconds = mediaElement.duration
            URL.revokeObjectURL(url) // Clean up the object URL
          })

          mediaElement.load() // Trigger metadata loading
        }

        this.files.push({
          index: this.files.length,
          file: file,
          name: this.getFileName(file),
          language: this.language,
          speakers: this.speaker_num,
          status: 'pending_upload',
          process: 0,
        })
        console.log(this.files[0])
        this.validateAudio()
      } else {
        for (let i = 0; i < event.target.files.length; i++) {
          const the_file = event.target.files[i]
          this.files.push({
            index: this.files.length,
            file: the_file,
            name: this.getFileName(the_file),
            language: this.language,
            speakers: this.speaker_num,
            status: 'pending_upload',
            process: 0,
          })
        }
        this.uploadAudio()
      }
    },
    getFileName(file) {
      // get file name without extension
      var file_name = file.name
      return file_name.replace(/\.[^/.]+$/, '')
    },
    clearErrorMessage() {
      this.errorMessage = ''
    },
    async submitAnonymousAudio() {
      if (!this.isValidEmail()) {
        this.errorMessage = 'please_fill_your_email'
        return
      }
      if (this.language == '') {
        this.errorMessage = 'please_select_language_first'
        return
      }
      if (this.mode === 'upload' && this.files.length == 0) {
        this.errorMessage = 'please_select_audio_first'
        return
      }
      if (this.mode === 'youtube' && this.youtube_link == '') {
        this.errorMessage = 'please_fill_youtube_link'
        return
      }
      // !isValidEmail() ||-->
      // <!--        language == '' ||-->
      // <!--        (mode === 'upload' && files.length == 0) ||-->

      // create anonymous user
      this.firebaseEvent('wa_trial_submit')
      await this.auth.signOut()
      this.isUploading = true
      signInAnonymously(this.auth)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user
          // console.log(user)
          var data = {
            username: user.uid,
            name: user.uid,
            is_anonymous: user.isAnonymous,
            fcm_token: user['accessToken'],
            firebase_token: user['accessToken'],
            firebase_uid: user.uid,
            display_language: this.$i18n.locale,
          }
          this.apiClient
            .register(data)
            .then((res) => {
              AuthHelper.userLogin(res)
              if (this.mode === 'upload') {
                // console.log(this.files[0].file)
                this.apiClient
                  .addAudio(
                    this.language,
                    this.speaker_num,
                    this.email,
                    this.files[0].file,
                    '',
                    this.isAnonymousMode,
                    this.files[0].name,
                    null,
                    this.folder_id,
                    (progressEvent) => {
                      this.anonymousProcess = progressEvent
                    },
                  )
                  .then((data) => {
                    // console.log(data)
                    this.$emit('uploaded', data.uuid)
                  })
              } else {
                this.submitYoutube()
              }
            })
            .catch((e) => {
              console.log(e)
              this.errorMessage = 'unexpected_error'
            })
        })
        .catch((error) => {
          var errorCode = error.code
          var errorMessage = error.message
          console.log(errorCode)
          console.log(errorMessage)
          deleteUser(this.auth)
          this.errorMessage = 'unexpected_error'
        })
    },
    validateAudio() {
      const fileSize = this.files[0].file.size // in bytes
      const maxSize = 200 * 1024 * 1024 // 200MB in bytes

      if (fileSize > maxSize) {
        this.fileSizeError = 'File size exceeds the limit of 500MB.'
        return false
      }
      return true
    },
    async getAudioDuration(file) {
      return new Promise((resolve, reject) => {
        const audio = new Audio()
        audio.src = URL.createObjectURL(file)
        audio.addEventListener('loadedmetadata', () => {
          resolve(Math.round(audio.duration))
        })
        audio.addEventListener('error', (e) => {
          console.error(e)
          reject(e)
        })
      })
    },
    async uploadAudio() {
      if (this.isUploading || this.uploadingFileId != null) {
        return
      }
      // get first file with status uploading
      const upload_file = this.files.find(
        (file) => file.status === 'pending_upload',
      )
      if (upload_file == null) {
        return
      }
      this.$emit('uploading')
      this.firebaseEvent('wa_upload_audio')
      const file_index = upload_file.index
      this.uploadingFileId = upload_file.index
      let audio_duration = 0
      try {
        audio_duration = await this.getAudioDuration(upload_file.file)
      } catch (ee) {
        console.log(ee)
      }
      // const audio_duration = await this.getAudioDuration(upload_file.file)
      this.files[file_index].status = 'uploading'
      this.apiClient
        .addAudio(
          this.language,
          this.speaker_num,
          this.email,
          upload_file.file,
          '',
          false,
          upload_file.name,
          audio_duration,
          this.folder_id,
          (progressEvent) => {
            this.files[file_index].process = progressEvent
          },
        )
        .then((data) => {
          // console.log(data)
          this.$emit('uploaded', data.uuid)
          this.files[file_index].status = 'uploaded'
        })
        .catch((error) => {
          // console.log('33')
          console.log(error)
          this.firebaseEvent('app_exception', {
            description: error,
          })
          this.files[file_index].status = 'failed'
        })
        .finally(() => {
          // console.log('44')
          this.uploadingFileId = null
          this.uploadAudio()
        })
    },
  },
}
</script>
<style scoped lang="scss">
//.dropbox {
//  outline: 2px dashed grey; /* the dash box */
//  //outline-offset: -10px;
//  background: #00000005;
//  border-radius: 12px;
//  //color: dimgray;
//  //padding: 10px 10px;
//  min-height: 40px; /* minimum height */
//  position: relative;
//  cursor: pointer;
//  margin-top: 12px;
//  margin-bottom: 12px;
//}
.dropbox {
  //outline: 2px dashed grey; /* the dash box */
  //outline-offset: -10px;
  background: #fff;
  //background: #e4e5e9;
  border-radius: 16px;
  //color: dimgray;
  //padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }

  .dropbox-init-message {
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    padding: 12px 24px;
    border-radius: 48px;
    font-weight: 700;

    img {
      margin-right: 8px;
    }
  }
}

.dropbox:hover {
  background: #f1f1f1; /* when mouse over to the drop zone, change color */
}

.app-checkbox {
  display: inline-block;
  align-items: center;

  ::v-deep .v-input__control {
    width: fit-content;
    flex-grow: 0;

    .v-input__slot {
      width: fit-content;
    }
  }
}

.page-wrap {
  padding: 0;
}

::v-deep .v-input input {
  height: 40px;
  max-height: 40px;
  padding-left: 12px;
}

::v-deep .v-select__slot {
  height: 40px;
  padding-left: 12px;
}

.input {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 12px;

  ::v-deep .v-input__slot {
    border-radius: 8px !important;
  }

  ::v-deep .v-text-field__details {
    display: none;
  }
}

.title-input {
  margin-bottom: 2px;
  display: block;

  label {
    font-weight: 400;
    font-size: 12px;
    color: #9196a6;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  .information-outline-icon {
    color: #9196a6;
  }
}

.section-s {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  .section-s-title {
    font-size: 1.1em;
    font-weight: 600;
    margin-right: 4px;
  }
}

:deep(.vs__dropdown-toggle),
:deep(.youtube_link) {
  background-color: white;
  border-radius: 48px;
  border: none;
  padding: 12px 16px;
  font-weight: 600;
}

:deep(.youtube_link) {
  margin-bottom: 8px;
}

:deep(.email-input) {
  padding: 12px 24px !important;
}
</style>
