import { ApiContext } from '../../types'

import { AuthHelper } from '@/api_client'
import { deserializeUser } from '../serializers/user'
import setPreferredCloudStorage from '@/api_client/api/setPreferredCloudStorage'
import updateUser from '@/api_client/api/updateUser'

export default async function getUser({ client, config }: ApiContext) {
  const accessToken = AuthHelper.getAccessToken()
  if (
    accessToken != null &&
    (localStorage.getItem('default_cloud_storage_region') == null ||
      localStorage.getItem('default_cloud_storage_region') == '')
  ) {
    await setPreferredCloudStorage()
    // update user
    await updateUser(
      { client, config },
      {
        default_cloud_storage_region:
          localStorage.getItem('default_cloud_storage_region') ??
          'soundtype-us',
        country: localStorage.getItem('country') ?? '',
        continent_code: localStorage.getItem('continent_code') ?? '',
      },
    )
  }
  const response = await client.users.show(
    AuthHelper.getAccessToken(),
    AuthHelper.getUserId(),
  )
  if (response.isSuccess()) {
    const success = response.success()
    return deserializeUser(success)
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}
