<template>
  <v-select
    label="text"
    :placeholder="$t('please_select_language')"
    :filter="langSearch"
    :reduce="(language1) => language1.value"
    :options="languages"
    class="input"
    :clearable="false"
    v-model="language"
    @option:selected="updateLanguage"
  >
    <template #option="{ text, en_text }">
      <div style="margin: 4px 0">
        <div>{{ text }}</div>
        <div style="margin-top: 2px">{{ en_text }}</div>
      </div>
    </template>
  </v-select>
</template>
<script>
import SupportedLanguages from '@/helpers/supportedLanguages'

export default {
  name: 'LanguageSelect',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      // Use 'modelValue' for v-model compatibility
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    langSearch(options, search) {
      return options.filter((option) =>
        [option.text, option.en_text].some((text) =>
          text.toLowerCase().includes(search.toLowerCase()),
        ),
      )
    },
    updateLanguage(language) {
      this.$emit('update:modelValue', language.value)
    },
  },
  data() {
    const tmp_langs = []
    for (let i = 0; i < SupportedLanguages.LANGUAGES.length; i++) {
      if (SupportedLanguages.LANGUAGES[i].value === '') {
        // don't use auto
        // tmp_langs.push({ text: this.$t('auto_detect'), value: '' })
      } else {
        tmp_langs.push(SupportedLanguages.LANGUAGES[i])
      }
    }
    return {
      language: this.modelValue,
      languages: tmp_langs,
    }
  },
}
</script>
<style lang="scss" scoped>
:deep().vs__search {
  color: grey;
}
</style>
