<template>
  <div class="auth-container">
    <CRow>
      <CCol :lg="4" class="left-col">
        <div class="home-icon">
          <a :href="soundTypeUrl">
            <CButton variant="ghost">
              <CIcon :icon="cilHome"></CIcon>
            </CButton>
          </a>
        </div>
        <div class="left-container">
          <a :href="soundTypeUrl">
            <div>
              <img class="logo-img" src="../../assets/logo_blue_small.png" />
              <div class="logo-title">SoundType AI</div>
              <div class="logo-des">Get more done with less effort</div>
            </div>
          </a>
        </div>
      </CCol>
      <CCol :lg="8" class="right-col">
        <div class="right-top-container">
          <div class="main-form">
            <div class="right-logo">
              <a
                :href="soundTypeUrl"
                style="position: absolute; top: 8px; left: 8px"
              >
                <CButton variant="ghost">
                  <CIcon :icon="cilHome"></CIcon>
                </CButton>
              </a>
              <img
                class="logo-img"
                src="../../assets/logo_blue_small.png"
                alt=""
              />
              <div class="logo-title">SoundType AI</div>
              <div class="logo-des">Get more done with less effort</div>
            </div>
            <div class="right-title">
              <div v-if="is_forgot_password">{{ $t('forgot_password') }}</div>
              <div v-else>{{ $t(is_sign_up ? 'sign_up' : 'sign_in') }}</div>
            </div>
            <CFormInput
              v-model="username"
              :label="$t('email')"
              :placeholder="$t('your_email')"
              autocomplete="email"
              class="s-input-class"
            />
            <CFormInput
              v-if="!is_forgot_password"
              type="password"
              class="s-input-class"
              v-model="password"
              :label="$t('password')"
              :placeholder="$t('your_password')"
              autocomplete="current-password"
              v-on:keyup.enter="enterClick()"
            />
            <div v-if="is_forgot_password"></div>
            <div v-else-if="is_sign_up" style="display: flex">
              <CFormCheck
                id="toc"
                :label="$t('i_agree_to_toc')"
                v-model="agreed"
              />
              <a
                :href="soundTypeUrl + 'terms-of-use'"
                target="_blank"
                class="toc"
              >
                [{{ $t('terms_and_conditions') }}]
              </a>
            </div>
            <div v-else class="forgot-password">
              <a :href="forgotPasswordUrl">
                {{ $t('forgot_password') }}
              </a>
            </div>
            <div
              v-if="errorMessage !== ''"
              style="color: red; margin-bottom: 8px"
            >
              {{ $t(errorMessage) }}
            </div>
            <div style="text-align: center">
              <div v-if="isResetPasswordEmailSent" style="color: green">
                {{ $t('reset_password_email_sent') }}
              </div>
              <CButton
                v-else-if="is_forgot_password"
                color="info"
                shape="rounded-pill"
                class="px-4 sign-in-btn"
                :disabled="!isDataValid || isLoading"
                @click="forgotPasswordClick(username)"
              >
                {{ $t('submit') }}
              </CButton>
              <CButton
                v-else-if="is_sign_up"
                color="info"
                shape="rounded-pill"
                class="px-4 sign-in-btn"
                :disabled="agreed === false || !isDataValid || isLoading"
                @click="signUpClick(username, password)"
              >
                {{ $t('sign_up') }}
              </CButton>
              <CButton
                v-else
                color="info"
                shape="rounded-pill"
                class="px-4 sign-in-btn"
                :disabled="!isDataValid || isLoading"
                @click="signInClick(username, password)"
              >
                {{ $t('sign_in') }}
              </CButton>

              <div v-if="!is_forgot_password" style="margin-bottom: 32px">
                <!-- social login -->
                <div class="or-divider">
                  <hr />
                  <div>{{ $t('or') }}</div>
                  <hr />
                </div>
                <CButton
                  color="light"
                  shape="rounded-pill"
                  class="social-btn"
                  @click="signInGoogleClick"
                >
                  <img :src="GoogleIcon" />

                  <span>{{ $t('sign_in_with_google') }}</span>
                </CButton>
                <CButton
                  color="light"
                  shape="rounded-pill"
                  class="social-btn"
                  @click="signInAppleClick"
                >
                  <img :src="AppleIcon" style="width: 24px; height: 24px" />
                  <span>{{ $t('sign_in_with_apple') }}</span>
                </CButton>
              </div>
              <div style="margin-top: 16px" v-if="is_forgot_password">
                <a :href="loginUrl" class="mode-change-link">
                  {{ $t('sign_in') }}
                </a>
                <a
                  style="margin-left: 8px"
                  :href="registerUrl"
                  class="mode-change-link"
                >
                  {{ $t('sign_up') }}
                </a>
              </div>
              <div style="margin-top: 16px" v-else-if="is_sign_up">
                <label class="mode-change-label">
                  {{ $t('already_have_an_account') }}
                </label>
                <a class="mode-change-link" :href="loginUrl">
                  {{ $t('sign_in') }}
                </a>
              </div>
              <div style="margin-top: 16px" v-else>
                <label class="mode-change-label">
                  {{ $t('dont_have_an_account') }}
                </label>
                <a class="mode-change-link" :href="registerUrl">
                  {{ $t('sign_up') }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="auth-footer">
          <div class="lang-container">
            <img
              src="../../assets/icons/language.svg"
              style="color: rgba(44, 56, 74, 0.95); margin-right: 4px"
            />
            <CFormSelect
              @change="updateDisplayLang($event.target.value)"
              v-model="displayLang"
              size="sm"
              class="language-select"
              style="max-width: 100px; margin-right: auto"
            >
              <option
                v-for="lang in SupportedLanguages.DISPLAY_LANGUAGES"
                :value="lang.value"
                :key="lang.value"
              >
                {{ lang.text }}
              </option>
            </CFormSelect>
          </div>
          <AllRightReserved />
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { AuthHelper } from '@/api_client'
import { CIcon } from '@coreui/icons-vue'
import { cilHome, cilList } from '@coreui/icons'
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  linkWithCredential,
  linkWithPopup,
  signInWithCredential,
  signOut,
  EmailAuthProvider,
} from 'firebase/auth'
// import AppFooter from '@/components/AppFooter.vue'
import SupportedLanguages from '@/helpers/supportedLanguages'
import {
  getAdditionalUserInfo,
  sendPasswordResetEmail,
  UserCredential,
} from '@firebase/auth'
import GoogleIcon from '../../assets/icons/google_logo.svg'
import AppleIcon from '../../assets/icons/apple_logo.svg'
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import AllRightReserved from '@/components/AllRightReserved.vue'
import { FirebaseError } from '@firebase/util'
import link from '@/assets/text/link'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Auth',
  created() {
    const lang = this.$route.query.lang
    if (lang) {
      this.$i18n.locale = lang
      this.displayLang = lang
    }
  },
  components: {
    AllRightReserved,
    CIcon,
    // AppFooter,
  },
  props: {
    is_sign_up: {
      type: Boolean,
      required: true,
    },
    is_forgot_password: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const email = ref('')
    const password = ref('')
    return {
      cilList,
      username: email,
      password,
    }
  },
  watch: {
    username: function (newValue, oldValue) {
      this.errorMessage = ''
    },
    password: function (newValue, oldValue) {
      this.errorMessage = ''
    },
  },
  methods: {
    signInGoogleClick() {
      const provider = new GoogleAuthProvider()
      const auth = getAuth()
      auth.languageCode = this.$i18n.locale
      this.handleSocialLoginUserCredential(provider)
    },
    signInAppleClick() {
      const provider = new OAuthProvider('apple.com')
      provider.addScope('email')
      provider.setCustomParameters({
        // Localize the Apple authentication screen in French.
        locale: this.$i18n.locale,
      })
      // const auth = getAuth()
      this.handleSocialLoginUserCredential(provider, 'apple')
      // signInWithPopup(auth, provider)
      //   .then((result) => {
      //     // // Apple credential
      //     const credential = OAuthProvider.credentialFromResult(result)
      //     this.handleSocialLoginUserCredential(credential)
      //   })
      //   .catch((error) => {
      //     this.handleFail(error)
      //   })
    },
    async handleSocialLoginUserCredential(provider) {
      if (this.auth.currentUser != null && this.auth.currentUser.isAnonymous) {
        // if (AuthHelper.getUserId() != null && AuthHelper.getUserId().length > 0) {
        const userCredential = await linkWithPopup(
          this.auth.currentUser,
          provider,
        )
        console.log(userCredential)
        console.log(userCredential.user)
        console.log(userCredential.user.providerData)
        try {
          // link to anonymous account

          // update link provider
          const user = userCredential.user
          const newUser = await this.apiClient.updateLinkProvider(
            user.providerData[0],
          )
          // update anonymous account to normal account
          AuthHelper.setIsAnonymous(false)
          const user2 = await this.apiClient.updateUser({
            is_anonymous: false,
            display_language: this.$i18n.locale,
          })

          AuthHelper.userLogin(user2)
          AuthHelper.setEmail(user2.email)
          this.$router.push('/')
          return
        } catch (ee) {
          console.log(ee)
          if (
            ee.code === 'auth/credential-already-in-use' ||
            ee.code === 'auth/provider-already-linked'
          ) {
            // this.errorMessage = 'email_already_used'
            await this.signUpWithCredentialFirebase(userCredential)
          } else {
            this.handleFail(ee)
          }
        }
      } else {
        const userCredential = await signInWithPopup(this.auth, provider)
        // new account
        console.log('new account')
        await this.signUpWithCredentialFirebase(userCredential)
        //
        // userCredential = await signInWithCredential(this.auth, credential)
        // var user = userCredential.user
        // // console.log(user)
        // var provider = null
        // if (user.providerData.length > 0) {
        //   var tmp_provider = user.providerData[0]
        //   provider = {
        //     uid: tmp_provider.uid,
        //     email: tmp_provider.email,
        //     display_name: tmp_provider.displayName,
        //     photo_url: tmp_provider.photoURL,
        //     phone_number: tmp_provider.phoneNumber,
        //     provider_id: tmp_provider.providerId,
        //   }
        // }
        // var data = {
        //   username: user.uid,
        //   name: user.uid,
        //   is_anonymous: user.isAnonymous,
        //   fcm_token: user.accessToken,
        //   firebase_token: user.accessToken,
        //   firebase_uid: user.uid,
        //   provider: provider,
        //   display_language: this.$i18n.locale,
        // }
        // this.signUpByData(data)
      }
    },
    async signUpWithCredentialFirebase(userCredential) {
      // new account
      // const userCredential = await signInWithCredential(this.auth, credential)
      var user = userCredential.user
      var additionalUserInfo = getAdditionalUserInfo(userCredential)
      if (additionalUserInfo.isNewUser) {
        var provider = null
        if (user.providerData.length > 0) {
          var tmp_provider = user.providerData[0]
          provider = {
            uid: tmp_provider.uid,
            email: tmp_provider.email,
            display_name: tmp_provider.displayName,
            photo_url: tmp_provider.photoURL,
            phone_number: tmp_provider.phoneNumber,
            provider_id: tmp_provider.providerId,
          }
        }
        var data = {
          username: user.uid,
          name: user.uid,
          is_anonymous: user.isAnonymous,
          fcm_token: user.accessToken,
          firebase_token: user.accessToken,
          firebase_uid: user.uid,
          provider: provider,
          display_language: this.$i18n.locale,
        }

        try {
          this.signUpByData(data)
        } catch (eee) {
          await user.delete()
          try {
            // signOut(this.auth)
          } catch (eeeee) {
            console.log(eeeee)
          }

          console.log(eee)
          this.errorMessage = 'unexpected_error'
        }
      } else {
        console.log(user)
        this.apiClient
          .logIn(user.uid, user.accessToken)
          .then((res) => {
            AuthHelper.userLogin(res)
            this.$router.push('/')
          })
          .catch((e) => {
            console.log(e)
            try {
              // signOut(this.auth)
            } catch (eeeee) {
              console.log(eeeee)
            }
            this.errorMessage = 'unexpected_error'
          })
      }
    },
    updateDisplayLang(value) {
      console.log('updateDisplayLang', this.displayLang)
      console.log('updateDisplayLang', value)
      this.displayLang = value
      // add lang as query to current url and refresh
      const url = this.$route.query.lang
        ? window.location.href.replace(
            `lang=${this.$route.query.lang}`,
            `lang=${this.displayLang}`,
          )
        : `${window.location.href}?lang=${this.displayLang}`
      window.location.href = url
      window.location.reload()
    },
    validateEmail() {
      // return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.username)
      return /.+@.+\..+/.test(this.username)
    },
    enterClick() {
      if (this.is_sign_up) {
        this.signUpClick(this.username, this.password)
      } else {
        this.signInClick(this.username, this.password)
      }
    },
    signUpByData(data) {
      this.apiClient
        .register(data)
        .then((res) => {
          AuthHelper.userLogin(res)
          this.$router.push('/')
        })
        .catch((e) => {
          throw e
          // console.log(e)
          // this.errorMessage = 'unexpected_error'
        })
    },
    forgotPasswordClick(username) {
      this.errorMessage = ''
      //TODO: set user language when changing language setting
      sendPasswordResetEmail(this.auth, username)
        .then(() => {
          this.isResetPasswordEmailSent = true
        })
        .catch((e) => {
          if (e instanceof FirebaseError) {
            // https://firebase.google.com/docs/auth/admin/errors
            // todo: handle all
            if (e.code === 'auth/user-not-found') {
              this.errorMessage = 'user_not_found'
            } else {
              console.log(e.name)
              console.log(e.code)
              console.log(e.message)
              this.errorMessage = 'unexpected_error'
            }
          } else {
            this.errorMessage = 'unexpected_error'
          }
          // console.log(e)
          // console.log(e.toString())
          //
          // if (e.toString() === 'auth/user-not-found') {
          //   this.errorMessage = 'user_not_found'
          // } else {
          //   this.errorMessage = 'unexpected_error'
          // }
        })
    },
    signUpClick(username, password) {
      console.log('signUpClick')
      this.isLoading = true
      // console.losignUpClg(AuthHelper.getIsAnonymous())
      // console.log(this.auth.currentUser)
      if (
        AuthHelper.getIsAnonymous() &&
        this.auth.currentUser != null &&
        this.auth.currentUser.isAnonymous
      ) {
        // link to anonymous account
        const credential = EmailAuthProvider.credential(username, password)
        linkWithCredential(this.auth.currentUser, credential)
          .then(async (userCredential) => {
            // Signed in
            var user = userCredential.user
            const st_user = await this.apiClient.logIn(
              user.uid,
              user.accessToken,
            )
            AuthHelper.userLogin(st_user)
            AuthHelper.setIsAnonymous(false)
            const newUser = await this.apiClient.updateUser({
              is_anonymous: false,
              display_language: this.$i18n.locale,
            })
            await this.apiClient.updateLinkProvider(user.providerData[0])
            AuthHelper.userLogin(newUser)
            AuthHelper.setEmail(user.providerData[0].email)
            this.$router.push('/')
          })
          .catch((e) => {
            this.handleFail(e)
          })
      } else {
        createUserWithEmailAndPassword(this.auth, username, password)
          .then((userCredential) => {
            // Signed in
            var user = userCredential.user
            // console.log(user)
            var provider = null
            if (user.providerData.length > 0) {
              var tmp_provider = user.providerData[0]
              provider = {
                uid: tmp_provider.uid,
                email: tmp_provider.email,
                display_name: tmp_provider.displayName,
                photo_url: tmp_provider.photoURL,
                phone_number: tmp_provider.phoneNumber,
                provider_id: tmp_provider.providerId,
              }
            }
            var data = {
              username: user.uid,
              name: user.uid,
              is_anonymous: user.isAnonymous,
              fcm_token: user.accessToken,
              firebase_token: user.accessToken,
              firebase_uid: user.uid,
              provider: provider,
              display_language: this.$i18n.locale,
            }
            try {
              this.signUpByData(data)
            } catch (ee) {
              user.delete().then(() => {
                // this.handleFail(ee)
              })
              console.log(ee)
              this.errorMessage = 'unexpected_error'
            }
          })
          .catch((e) => {
            this.handleFail(e)
          })
      }
    },
    handleFail(e) {
      this.isLoading = false
      if (
        e.code === 'auth/wrong-password' ||
        e.code === 'auth/email-already-in-use'
      ) {
        this.errorMessage = 'email_already_in_use'
      } else if (e.code === 'auth/weak-password') {
        this.errorMessage = 'weak_password'
      } else if (e.code === 'auth/too-many-requests') {
        this.errorMessage = 'too_many_attempts'
      } else if (e.code === 'auth/provider-already-linked') {
        this.errorMessage = 'user_already_registered'
        // await this.$fire.auth.signOut()
      } else if (e.code === 'auth/popup-blocked') {
        this.errorMessage = 'popup_blocked'
        // await this.$fire.auth.signOut()
      } else if (
        e.code === 'auth/cancelled-popup-request' ||
        e.code === 'auth/popup-closed-by-user'
      ) {
        this.errorMessage = ''
      } else {
        this.errorMessage = e.message
      }
    },
    async signInClick(username, password) {
      console.log('signInClick')
      this.isLoading = true
      signInWithEmailAndPassword(this.auth, username, password)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user
          console.log('wtf??')
          console.log(user)
          this.apiClient
            .logIn(user.uid, user.accessToken)
            .then((res) => {
              AuthHelper.userLogin(res)
              this.$router.push('/')
            })
            .catch((e) => {
              console.log(e)
              try {
                // signOut(this.auth)
              } catch (eeeee) {
                console.log(eeeee)
              }
              this.isLoading = false
              this.errorMessage = 'unexpected_error'
            })
        })
        .catch((e) => {
          this.isLoading = false
          if (
            e.code === 'auth/wrong-password' ||
            e.code === 'auth/user-not-found'
          ) {
            this.errorMessage = 'invalid_email_or_password'
          } else if (e.code === 'auth/too-many-requests') {
            this.errorMessage = 'too_many_attempts'
          } else {
            this.errorMessage = e.message
          }
        })
      // this.apiClient
      //   .logIn(username, password)
      //   .then(() => {
      //     this.$router.push('/')
      //   })
      //   .catch(() => {
      //     alert('Please enter correct email and password')
      //   })
    },
  },
  // mounted() {
  //   const auth = AuthHelper.getOAuthToken()
  //   if (auth.access_token != null) {
  //     this.$router.push('/')
  //   }
  // },
  data() {
    return {
      link,
      GoogleIcon,
      AppleIcon,
      SupportedLanguages,
      displayLang: this.$i18n.locale,
      cilHome,
      isLoading: false,
      auth: getAuth(),
      errorMessage: '',
      isResetPasswordEmailSent: false,
      agreed: false,
      isDataValid: computed(() => {
        if (this.is_forgot_password) {
          return this.username !== '' && this.validateEmail()
        } else {
          return (
            this.username !== '' && this.validateEmail() && this.password !== ''
          )
        }
      }),
    }
  },
  computed: {
    soundTypeUrl() {
      return this.displayLang === 'en'
        ? 'https://soundtype.ai/'
        : 'https://soundtype.ai/' + this.displayLang + '/'
    },
    loginUrl() {
      return this.displayLang === 'en'
        ? link.login
        : link.login + '?lang=' + this.displayLang
    },
    forgotPasswordUrl() {
      return this.displayLang === 'en'
        ? link.forgot_password
        : link.forgot_password + '?lang=' + this.displayLang
    },
    registerUrl() {
      return this.displayLang === 'en'
        ? link.register
        : link.register + '?lang=' + this.displayLang
    },
  },
}
</script>
<style lang="scss" scoped>
:deep(.form-control) {
  border-radius: 48px !important;
}

.sign-in-btn {
  width: 100%;
  min-width: 200px;
  color: white;
  margin-top: 24px;
  height: 48px;
}

.footer {
  background: none;
  font-size: 13px;
}

.social-btn {
  width: 100%;
  min-width: 200px;
  display: flex;
  border: 1px solid #e5e5e5;
  background: white;
  margin-bottom: 12px;
  height: 48px;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 12px;
  }

  p,
  span {
    margin-right: auto;
    line-height: 24px;
  }
}

.auth-container {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;

  .row {
    height: 100%;

    .left-col {
      align-items: center;
      display: flex;
      @media (max-width: 991px) {
        display: none;
      }

      .home-icon {
        position: absolute;
        top: 24px;
        left: 24px;
      }

      .left-container {
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        text-align: center;

        a {
          text-decoration: unset;
          color: unset;
        }
      }
    }
  }

  .right-col {
    background: #f8f8f9;
    height: 100%;
    overflow: auto;

    .right-logo {
      display: none;
    }

    // responsive layout for mobile
    @media (max-width: 991px) {
      .sign-in-btn {
        width: 100%;
      }
      .right-logo {
        display: block;
        margin-top: 60px;
        text-align: center;
        margin-bottom: 20px;
      }
      .right-title {
        display: none;
      }
      :deep(.form-label) {
        display: none;
      }
    }

    .right-top-container {
      height: 95%;
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        height: unset;
      }

      .main-form {
        width: 80%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 767px) {
          max-width: 100%;
        }

        .right-title {
          height: 100px;
          text-align: center;
          padding-top: 20px;
          font-size: 32px;
          font-weight: 700;
          line-height: 40px;
          letter-spacing: 0.01em;
          color: black;
        }

        :deep(.form-label) {
          color: #9196a6;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
        }

        :deep(.s-input-class) {
          padding: 12px 20px 12px 20px;
          border: none;
          margin-bottom: 12px;
        }

        .forgot-password {
          text-align: right;

          a {
            color: #1a73e8;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            text-decoration: none;
          }
        }

        .mode-change-label {
          color: #5f6475;
          line-height: 24px;
          font-size: 14px;
          margin-right: 8px;
        }

        .mode-change-link {
          color: #1a73e8;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          text-decoration: none;
        }

        .or-divider {
          display: flex;
          align-items: center;
          margin-top: 24px;
          margin-bottom: 24px;
          @media (max-width: 767px) {
            margin-top: 16px;
            margin-bottom: 16px;
          }

          hr {
            width: 100%;
            color: #989898;
          }

          div {
            color: #989898;
            margin-left: 12px;
            margin-right: 12px;
          }
        }
      }
    }
  }
}

.auth-footer {
  position: relative;
  bottom: 0;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: unset;
    display: block;
    text-align: center;
    .lang-container {
      position: inherit !important;
      margin-top: 16px;
      margin-bottom: 12px;

      img {
        margin-left: auto;
      }
    }
  }

  .lang-container {
    display: flex;
    margin-left: auto;
    align-items: center;
    position: fixed;
    right: 24px;

    .language-title {
      font-size: 14px;
      margin-left: auto;
      margin-right: 8px;
    }

    :deep(.language-select) {
      max-width: 100px;
      background-color: transparent;
      border: none;
    }
  }
}

.toc {
  margin-left: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #9196a6;
  text-decoration: unset;
}

.logo-img {
  width: 72px;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    width: 52px;
    margin-bottom: 12px;
  }
}

.logo-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 4px;
  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 0px;
  }
}

.logo-des {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.btn {
  font-weight: 700;
  height: 48px;
  font-size: 15px;
  box-shadow: none !important;
  @media (max-width: 767px) {
    height: unset;
    font-size: 14px;
  }
}
</style>
