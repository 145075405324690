<template>
  <div class="powered-by">
    © Innosquares Limited - All Rights Reserved {{ new Date().getFullYear() }}
  </div>
</template>

<script lang="ts"></script>

<style lang="scss" scoped>
.powered-by {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #9196a6;
}
</style>
