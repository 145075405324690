import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'

export default async function getStripeDefaultCurrency({
  client,
  config,
}: ApiContext) {
  const response = await client.purchases.getStripeDefaultCurrency(
    AuthHelper.getAccessToken(),
  )
  if (response.isSuccess()) {
    return response.success()
  } else {
    throw response.fail()
  }
}
