import Http from '../Http'
import { IQuery } from '../interfaces/Query'
import { Routes } from '../routes'

export default class Audios extends Http {
  public async show(
    token: string,
    id: number,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.audioPath(id),
      token,
      params,
    )) as any
  }

  public async export_file(
    token: string,
    id: number,
    params: IQuery = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.exportFilePath(id),
      token,
      params,
      null,
      true,
      'blob',
    )) as any
  }

  public async showByUUID(token: string, uuid: string): Promise<any> {
    return (await this.spreeResponse('get', Routes.audioUUIDPath(), token, {
      uuid: uuid,
    })) as any
  }

  public async list(token: string, params: IQuery = {}): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.audiosPath(),
      token,
      params,
    )) as any
  }

  public async update(token: string, id, data): Promise<any> {
    return (await this.spreeResponse(
      'put',
      Routes.audioPath(id),
      token,
      {},
      data,
    )) as any
  }

  public async create(token: string, params: IQuery = {}, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.audiosPath(),
      token,
      params,
      data,
      false,
    )) as any
  }

  public async reSubmit(token: string, audio_id): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.audiosReSubmitPath(audio_id),
      token,
    )) as any
  }

  public async remove(token: string, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.audiosRemovePath(),
      token,
      {},
      data,
    )) as any
  }

  public async summarize(token: string, audioId, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.audiosSummarizePath(audioId),
      token,
      {},
      data,
    )) as any
  }
  public async translate(token: string, audioId, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.audiosTranslatePath(audioId),
      token,
      {},
      data,
    )) as any
  }

  public async transcribeRemaining(token: string, audioId): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.audiosTranscribeRemainingPath(audioId),
      token,
      {},
    )) as any
  }

  public async sendMessages(token: string, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.messagesPath(),
      token,
      {},
      data,
    )) as any
  }

  public async getMessages(token: string, audio_id): Promise<any> {
    return (await this.spreeResponse('get', Routes.messagesPath(), token, {
      audio_id: audio_id,
    })) as any
  }

  public async getMessageResponse(token: string, messageId): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.messageResponsePath(messageId),
      token,
    )) as any
  }

  public async getAiServiceRatePath(
    token: string,
    audioId,
    params = {},
  ): Promise<any> {
    return (await this.spreeResponse(
      'get',
      Routes.aiServiceRatePath(audioId),
      token,
      params,
    )) as any
  }

  public async restore(token: string, data): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.audiosRestorePath(),
      token,
      {},
      data,
    )) as any
  }

  public async generateSignedUrl(
    token: string,
    filename: string,
    file_type: string,
  ): Promise<any> {
    return (await this.spreeResponse(
      'post',
      Routes.audiosGenerateSignedUrlPath(),
      token,
      {},
      { filename: filename, file_type: file_type },
    )) as any
  }
}
