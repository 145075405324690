import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeProduct } from '@/api_client/api/serializers/product'

export default async function getProducts(
  { client, config }: ApiContext,
  lang = 'en',
  default_currency = '',
  params = {},
) {
  const response = await client.products.list('', params)
  if (response.isSuccess()) {
    const success = response.success()
    const data = success.data
    const meta = success.meta

    let currency = ''
    if (default_currency != '') {
      currency = default_currency
    } else {
      switch (lang) {
        case 'ja':
          currency = 'jpy'
          break
        case 'de':
        case 'es':
        case 'fr':
        case 'it':
        case 'pt':
          currency = 'eur'
          break
        case 'ko':
          currency = 'krw'
          break
        case 'vi':
          currency = 'vnd'
          break
        case 'zh':
          currency = 'twd'
          break
        default:
          currency = 'usd'
      }
    }

    return {
      data: data.map((item) => deserializeProduct(item, currency)),
      meta: meta,
    }
  } else {
    console.log('getProducts: fail')
    throw response.fail()
  }
}
