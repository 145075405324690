export const en = {
  sign_in: 'Sign in',
  email: 'Email',
  password: 'Password',
  i_agree_to_toc: 'I agree to the terms and conditions.',
  terms_and_conditions: 'Terms and conditions',
  privacy_policy: 'Privacy policy',
  already_have_an_account: 'Already have an account?',
  sign_up: 'Sign up',
  dont_have_an_account: "Don't have an account?",
  forgot_password: 'Forgot password?',
  email_already_in_use: 'Email already in use',
  weak_password: 'Weak password',
  too_many_attempts: 'Too many attempts',
  invalid_email_or_password: 'Invalid email or password',
  user_already_registered: 'User already registered',
  unexpected_error: 'Unexpected error',
  audios: 'Audios',
  name: 'Name',
  auto: 'Auto',
  auto_detect: 'Auto detect',
  speaker_num: 'Number of Speakers',
  speaker_num_reason:
    'If you tell us the number of speakers, we will provide a better transcription result.',
  audio_language: 'Audio Language',
  your_email: 'Your Email',
  email_result: 'We will send you an email with the transcription result.',
  provide_language:
    'If you tell us the audio language, we will provide a better transcription result.',
  upload_audio: 'Upload Audio',
  upload_file: 'Upload File',
  process_audio: 'Processing Your File',
  process_audio_des:
    'Please wait while your audio is being transcribed. It may take a few minutes.',
  uploading: 'Uploading',
  pending: 'Pending',
  uploaded: 'Uploaded',
  failed: 'Failed',
  status: 'Status',
  language: 'Language',
  speakers: 'Speakers',
  duration: 'Duration',
  done: 'Done',
  close: 'Close',
  queuing: 'Queuing',
  created_at: 'Created at',
  records: 'Records',
  transcribe: 'Transcribe',
  delete: 'Delete',
  processing: 'Processing',
  from_youtube: 'From Youtube',
  upload_files: 'Upload files',
  place_youtube_link_here: 'Place Youtube link here',
  submit: 'Submit',
  email_notification:
    "The turnaround time depends on the length and complexity of the audio file. Generally, you can expect to receive your transcription within a few hours. We'll notify you via email as soon as it's ready.",
  filters: 'Filters',
  filter: 'Filter',
  replace: 'Replace',
  share_and_export: 'Share and Export',
  replace_all: 'Replace All',
  next: 'Next',
  previous: 'Previous',
  find: 'Find',
  replace_with: 'Replace with',
  x_of_x: '{current} of {total}',
  scroll_lock: 'Scroll Lock',
  tags: 'Tags',
  bookmark: 'Bookmark',
  audio: 'Audio',
  cancel: 'Cancel',
  export: 'Export',
  export_format: 'Export format',
  copy_text: 'Copy text',
  monologue: 'Monologue',
  include_timestamps: 'Include timestamps',
  include_speaker_names: 'Include speaker names',
  combine_same_speaker: 'Combine same speaker segments',
  export_mode: 'Export mode',
  no_preview: 'No preview',
  copy: 'Copy',
  copied: 'Copied',
  unlock_pro_feature:
    "To unlock this feature, upgrade to the 'Pro' subscription plan.",
  pro_features: 'Pro features',
  pro_minutes: '1800 minutes / month',
  pro_interact_with_audio: 'Interact with audio',
  pro_interact_with_audio_des: 'Ask questions to your audio',
  pro_summary: 'AI Summary',
  pro_summary_des: 'Summarize your audio',
  pro_priority: 'Priority Queue',
  pro_priority_des: 'Processed audio with priority',
  pro_multi_speakers: 'Multi-Speakers Recognition',
  pro_multi_speakers_des: 'Accurately detects and labels different speakers',
  redirect_to_stripe: 'You will be redirected to Stripe to complete payment.',
  x_per_month: '{x} / Month',
  x_per_year: '{x} / Year',
  trash: 'Trash',
  dashboard: 'Dashboard',
  logout: 'Logout',
  are_you_sure_to_delete: 'Are you sure to delete selected records?',
  restore: 'Restore',
  are_you_sure_to_restore: 'Are you sure to restore selected records?',
  deleted_at: 'Deleted at',
  hidden_segment: '{x} segments are hidden after {y} minutes.',
  settings: 'Settings',
  subscription: 'Subscription',
  default_transcription_language: 'Default Transcription Language',
  contact_us: 'Contact Us',
  security: 'Security',
  about_us: 'About Us',
  download_app: 'Download App',
  download: 'Download',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Upload voice',
  no_audio_found: 'No audio found',
  please_input_valid_email_first: 'Please input valid email first',
  handling_audio: 'We are transcribing your audio. Please wait a moment.',
  welcome_to_leave:
    'You are welcome to leave this page as We will send you an email with the transcription result.',
  send_your_result: 'We will send you an email with the transcription result.',
  transcribe_audio_thank:
    "Thank you for using our transcription service! We're working hard on transcribing your audio and appreciate your patience.",
  transcribe_audio_no_stay:
    "Your transcription may take a little while, but don't worry, you don't need to stay on this page. We'll email you the results as soon as they're ready.",
  date: 'Date',
  sign_up_to_access_more_functions: 'Sign up to access more functions',
  you_can_use_functions: 'You can use the following functions after login',
  know_more: 'Know more',
  modify_transcription: 'Modify text transcription',
  play_audio: 'Play audio',
  content_filters: 'Content filters',
  export_diff_type: 'Export different types of files',
  screenshots: 'Screenshots',
  free_trial: 'Free Trail',
  pricing: 'Pricing',
  faq: 'FAQ',
  contact: 'Contact',
  login: 'Login',
  register: 'Register',
  please_login_before_purchase: 'Please login before purchase',
  no_records_found: 'No records found',
  x_minutes: '{x} Minutes',
  confirm: 'Confirm',
  notification_mobile_app: 'Mobile App Notification',
  notification_mobile_app_des:
    'Receive mobile app notification when transcription result is ready',
  notification_email: 'Email Notification',
  notification_email_des:
    'Receive email notification when transcription result is ready',
  video: 'Video',
  or: 'Or',
  sign_in_with_google: 'Sign in with Google',
  sign_in_with_apple: 'Sign in with Apple',
  login_method: 'Login Method',
  delete_account: 'Delete Account',
  delete_account_confirm_3_time: 'Press delete button 3 times to confirm',
  popup_blocked: 'Popup blocked',
  account_info: 'Account Information',
  redeem: 'Redeem',
  redeem_code: 'Redeem Code',
  summary_des1:
    'Summary service provides a summary of the transcription result. It can summarize the transcription result into a few sentences.',
  summary_des2: 'Your old summary will be replaced by the new summary.',
  consume_transcription_quota: 'Consume transcription quota',
  summary: 'Summary',
  query: 'Query',
  mins_per_query: '{x} minutes per query',
  information_not_found: 'Information not found',
  permission_deny: 'Permission deny',
  please_login_to_check_the_result: 'Please login to check the result',
  audio_length: 'Audio Length',
  delete_permanently: 'Delete Permanently',
  are_you_sure_to_delete_permanently: 'Are you sure to delete permanently?',
  delete_audios_move_to_bin:
    'The deleted records will be moved to the bin. All the records in the bin will be deleted after 30 days.',
  payment_id: 'Payment ID',
  current_plan: 'Current plan',
  plan_name: 'Plan name',
  expire_date: 'Expire date',
  subscription_method: 'Subscription method',
  payment_method: 'Payment method',
  payment_history: 'Payment history',
  amount: 'Amount',
  invoice: 'Invoice',
  receipt: 'Receipt',
  link: 'Link',
  edit: 'Edit',
  back: 'Back',
  return: 'Return',
  cancel_subscription: 'Cancel subscription',
  cancel_subscription_google_play:
    'On your Android device > Go to Google Play Store > Account > Payments and subscriptions > Subscriptions > SoundType AI > Cancel subscription',
  cancel_subscription_apple_store:
    'On your iOS device > Go to Settings > [your name] > Subscriptions > SoundType AI > Cancel subscription',
  delete_account_des: 'Delete account will delete all your data permanently.',
  delete_account_subscription:
    'If you have a subscription, please cancel your subscription first.',
  keep_subscription: 'Keep Subscription',
  cancel_subscription_feature1: 'Recordings',
  cancel_subscription_feature1_des:
    'You will only be able to view the first {x} minutes of your recordings.',
  cancel_subscription_feature2: 'Transcription time',
  cancel_subscription_feature2_des:
    'You will only be able to transcribe {x} minutes per month.',
  cancel_subscription_count: 'Please press the button {x} times to confirm',
  next_payment_date: 'Next payment date',
  basic_plan: 'Basic Plan',
  resume_subscription: 'Resume subscription',
  active: 'Active',
  expired: 'Expired',
  auto_renewal_clause: 'Auto-renewal clause',
  auto_renewal_payment_web:
    'Your subscription will be automatically renewed unless you cancel it at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can manage and cancel your subscriptions by going to Settings > Billing.',
  auto_renewal_terms_and_conditions:
    'By subscribing, you agree to our Terms and Conditions and Privacy Policy.',
  payment: 'Payment',
  monthly: 'Monthly',
  annual: 'Annual',
  minutes_per_month: '{x} minutes/month',
  buy_now: 'Buy Now',
  yearly: 'Yearly',
  yearly_save: 'Save up to {x}%',
  pro_plan: 'Pro',
  business_plan: 'Business',
  enterprise_plan: 'Enterprise',
  everything_in_basic: 'Everything in Basic, plus',
  everything_in_pro: 'Everything in Pro, plus',
  everything_in_business: 'Everything in Business, plus',
  ai_chat_x: 'AI Chat x {x}',
  ai_summary_x: 'AI Summary x {x}',
  max_x_minutes_per_transcription: 'Max {x} minutes per transcription',
  basic_des2: 'Speaker identification',
  basic_des3: 'Audio/video file transcription',
  basic_des4: 'Review & edit transcripts',
  pro_des2: 'Priority Queue',
  pro_des3: 'Export in SRT format',
  pro_des4: 'Unlimited AI Summary',
  business_des1: 'Usage analytics report',
  business_des2: 'Collaborative workspace with team members',
  business_des3: 'Share and edit transcripts within your team.',
  business_des4: 'Control sharing permissions',
  coming_soon: 'Coming Soon',
  get_started: 'Get Started',
  bill_annually_x: 'Billed {x} annually',
  no_credit_card_required: 'No credit card required',
  save_x: 'Save {x}%',
  price_per_month: ' / month',
  not_available: 'Not available',
  pricing_title: 'Pricing',
  upgrade: 'Upgrade',
  ask_question_to_your_audio: 'Ask question to your audio',
  monthly_minutes: 'Monthly minutes',
  payment_interval: 'Payment interval',
  annually: 'Annually',
  billing: 'Billing',
  subscription_plan: 'Plan',
  please_cancel_your_current_subscription_first:
    'Please cancel your current subscription first',
  please_cancel_your_current_subscription_first_des:
    "To change your subscription plan, you'll need to cancel your current subscription first.",
  cancel_subscription_instruction:
    'Go to Settings > Billing > Cancel Subscription',
  website_version: 'Website Version',
  change_to_annual_plan: 'Change to Annual Plan',
  update_annual_mobile_platform_reminder: 'Reminder',
  update_annual_mobile_platform_reminder_des:
    'Please notify that you need to cancel your current subscription on {x} before changing to the annual plan. Otherwise, you will be charged twice.',
  i_understand: 'I understand',
  no_credit: 'Not enough minutes',
  re_submit: 'Re-submit',
  loading_audio: 'Loading audio',
  your_password: 'Your password',
  home: 'Home',
  title_a_z: 'Title A-Z',
  title_z_a: 'Title Z-A',
  older_first: 'Older first',
  newest_first: 'Newest first',
  table_item_count: '{x} - {y} of {z} items',
  starred: 'Starred',
  search: 'Search',
  create_folder: 'Create folder',
  folder_name: 'Folder name',
  create: 'Create',
  export_preview: 'Export preview',
  combine_segments: 'Combine segments',
  export_audio_only:
    'You can export audio only when the audio is transcribing.',
  transcribe_remaining_x_minutes: 'Transcribe remaining {x} minutes',
  x_minutes_remain_be_transcribed: '{x} minutes remain to be transcribed',
  upgrade_plan_to_transcribe: 'Upgrade your plan to transcribe the full audio',
  only_paid_user_can_use: 'Only paid user can transcribe the remaining minutes',
  converting_to_audio: 'Converting to audio',
  move_to_folder: 'Move to folder',
  move_audios_to_folder: 'Move the selected audios to the folder',
  please_select_folder: 'Please select a folder',
  empty_folder: 'Empty folder',
  move: 'Move',
  user_not_found:
    'User not found. The specified user could not be located. Please verify the details and try again, or contact support if further assistance is needed.',
  invalid_link: 'Invalid link',
  translation: 'Translation',
  translate: 'Translate',
  translate_des1:
    'Translation service provides a AI translation of the transcription result. It can translate the transcription result into a different language.',
  translate_des2: 'The translated text is not editable in this time.',
  translate_des3:
    'Your old translation will be replaced by the new translation.',
  translating: 'Translating',
  translating_des:
    'The translation process is in progress. Please wait a moment. We will notify you when the translation is ready.',
  please_select_language: 'Please select a language',
  display_language: 'Display language',
  both: 'Both',
  original: 'Original',
  translated: 'Translated',
  default_payment_currency_x_set:
    'Your payment currency is set to {x}. If you want to change it, please contact us at {email} .',
  invalid_link_des:
    'The YouTube link is invalid. Live videos, playlists, private, age-restricted, or region-locked content are not supported.',
  free_member_limited_view:
    'Basic plan user will only have access to the first {x} minutes of the transcription result.',
  free_user_service_once: 'Basic plan user can only use this service once.',
  free_user_summary:
    'Basic plan user can only use this service once. Please upgrade to Pro to summarize more audios.',
  free_user_query:
    'Basic plan user can only use this service once. Please upgrade to Pro to ask more query.',
  cancel_subscription_des:
    'Your subscription will be canceled at the end of the current billing cycle({x}). After that date, you will be downgraded to the Basic plan, and you will no longer be able to access the features of the Pro plan:',
  free_member_limited_transcribe_view:
    'Only the first {x} minutes will be transcribed and viewable for Basic plan user',
  reset_password_email_sent:
    'Please check your email. If this account exists, a password reset email will be sent.',
  m_downloads: '1M+ Downloads',
  provider: 'Provider',
  size: 'Size',
  category: 'Category',
  productivity: 'Productivity',
  languages: 'Languages',
  languages_90: '90+ languages',
  app_des:
    'Transform spoken words into text effortlessly with SoundType AI! Our cutting-edge app converts voice recordings and audio files into highly accurate, text-based transcriptions. Whether you’re working with meetings, interviews, podcasts, or video content, SoundType AI delivers precision and efficiency.<br><br>Key features include:<br>- Advanced Speaker Identification: Distinguish individual speakers seamlessly.<br>- AI-Powered Summaries: Generate concise and insightful summaries of your transcriptions.<br>- Global Language Support: Transcribe conversations in over 90 languages with ease.<br>- Enhanced Audio Features: Tailored for professionals, creators, and businesses alike.<br><br>Say goodbye to tedious manual transcription and embrace a smarter, faster way to turn audio into text with SoundType AI.',
  click_or_drop_file: 'Click or drop audio / video',
  remain_x_y_minutes: 'Remain {x}/{y} minutes',
  my_account: 'My account',
  please_fill_your_email: 'Please fill your email',
  please_select_language_first: 'Please select language',
  please_select_audio_first: 'Please select audio',
  please_fill_youtube_link: 'Please fill Youtube link',
  speaker_person_x: 'Speaker {x}',
}
