<template>
  <div></div>
  <!--  <CFooter>-->
  <!--    <div>-->
  <!--      <a :href="soundTypeUrl" target="_blank"> SoundType AI </a>-->
  <!--      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>-->
  <!--    </div>-->
  <!--    <div class="ms-auto">-->
  <!--      <span class="me-1">Powered by</span>-->
  <!--      <a href="https://innosq.com" target="_blank">Innosquares Limited</a>-->
  <!--    </div>-->
  <!--  </CFooter>-->
</template>

<script lang="ts">
export default {
  name: 'AppFooter',
  data() {
    return {
      displayLang: this.$i18n.locale,
    }
  },
  computed: {
    soundTypeUrl() {
      return this.displayLang === 'en'
        ? 'https://soundtype.ai/'
        : 'https://soundtype.ai/' + this.displayLang + '/'
    },
  },
}
</script>
