import { ApiConfig, ApiContext } from '../../types'
import { deserializeUser } from '@/api_client/api/serializers/user'
import { User } from '@/api_client/types/user'
import setPreferredCloudStorage from '@/api_client/api/setPreferredCloudStorage'

export default async function register(
  { client, config }: ApiContext,
  data,
): Promise<User> {
  await setPreferredCloudStorage()

  const response = await client.users.create({
    ...data,
    default_cloud_storage_region:
      localStorage.getItem('default_cloud_storage_region') ?? 'soundtype-us',
    country: localStorage.getItem('country') ?? '',
    continent_code: localStorage.getItem('continent_code') ?? '',
  })
  // console.log('response')
  // console.log(response)
  if (response.isSuccess()) {
    const payload = response.success()
    // console.log(payload)
    return deserializeUser(payload)
  } else {
    throw response.fail()
  }
}
