// const prefix = '/#'
const prefix = ''

const link = {
  domain: 'https://app.soundtype.ai',
  login: prefix + '/login',
  register: prefix + '/register',
  billing: prefix + '/billing',
  trash: prefix + '/trash',
  purchases: prefix + '/purchases',
  forgot_password: prefix + '/forgot_password',
  dashboard: prefix + '/dashboard',
  privacy_policy: '/privacy-policy',
  terms_of_use: '/terms-of-use',
  security: '/security',
  convert_youTube_to_mp3: '/convert-youtube-to-mp3',
  transcribe_audio_to_text: '/transcribe-audio-to-text',
  transcribe_video_to_text: '/transcribe-video-to-text',
  audio_summarizer_ai: '/audio-summarizer-ai',
}

export default link
