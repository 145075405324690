<template>
  <CModal
    :visible="visible"
    @close="$emit('close')"
    alignment="center"
    size="lg"
  >
    <CModalHeader style="color: black">
      <CModalTitle>{{ $t('pro_features') }}</CModalTitle>
    </CModalHeader>
    <CModalBody style="color: black">
      <div class="pro__features">
        <TimelapseIcon class="pro__features__icon" />
        <div>
          <div class="pro__features__title">{{ $t('pro_minutes') }}</div>
          <div class="pro__features__subtitle"></div>
        </div>
      </div>
      <div class="pro__features">
        <Numeric1Icon class="pro__features__icon" />
        <div>
          <div class="pro__features__title">{{ $t('pro_priority') }}</div>
          <div class="pro__features__subtitle">
            {{ $t('pro_priority_des') }}
          </div>
        </div>
      </div>
      <div class="pro__features">
        <AccountMultipleIcon class="pro__features__icon" />
        <div>
          <div class="pro__features__title">{{ $t('pro_multi_speakers') }}</div>
          <div class="pro__features__subtitle">
            {{ $t('pro_multi_speakers_des') }}
          </div>
        </div>
      </div>
      <div class="pro__features">
        <MessageTextIcon class="pro__features__icon" />
        <div>
          <div class="pro__features__title">
            {{ $t('pro_interact_with_audio') }}
          </div>
          <div class="pro__features__subtitle">
            {{ $t('pro_interact_with_audio_des') }}
          </div>
        </div>
      </div>
      <div class="pro__features">
        <FileDocumentOutlineIcon class="pro__features__icon" />
        <div>
          <div class="pro__features__title">{{ $t('pro_summary') }}</div>
          <div class="pro__features__subtitle">
            {{ $t('pro_summary_des') }}
          </div>
        </div>
      </div>
      <hr style="margin-bottom: 20px" />
      <div style="font-weight: bold; font-size: 20px">
        {{ $t('auto_renewal_clause') }}
      </div>
      <div class="pro__features">
        <div>
          <div class="pro__features__title">{{ $t('payment') }}</div>
          <div class="pro__features__subtitle">
            {{ $t('auto_renewal_payment_web') }}
          </div>
        </div>
      </div>
      <div class="pro__features">
        <div>
          <div class="pro__features__title">
            {{ $t('terms_and_conditions') }}
          </div>
          <div class="pro__features__subtitle">
            {{ $t('auto_renewal_terms_and_conditions') }}
          </div>
        </div>
      </div>
      <hr style="margin-bottom: 20px" />
      <div
        style="font-size: 14px; color: grey; margin-bottom: 8px"
        v-if="!isAnonymous"
      >
        {{ $t('redirect_to_stripe') }}
      </div>
      <div style="font-size: 14px; color: grey" v-else>
        {{ $t('please_login_before_purchase') }}
      </div>
      <div>
        <CLoadingButton
          :loading="proLoading"
          class="purchase-button"
          color="light"
          :disabled="proLoading"
          v-if="pro != null"
          @click="paymentClick(pro)"
        >
          <label style="font-weight: bold">{{ $t('monthly') }}</label>
          <label style="margin-left: auto">
            {{
              filters.currency(
                pro.currency_price.price,
                pro.currency_price.currency,
              )
            }}
          </label>
          <!--            {{ 'Pro - ' + $t('x_per_month', { x: proPrice.display_price }) }}-->
        </CLoadingButton>

        <div style="height: 8px" />

        <CLoadingButton
          :loading="proYearLoading"
          class="purchase-button"
          color="light"
          :disabled="proYearLoading"
          v-if="proYear != null"
          @click="paymentClick(proYear)"
        >
          <label style="font-weight: bold">{{ $t('annual') }}</label>
          <label
            style="
              background: #e80000;
              border-radius: 40px;
              font-weight: bold;
              font-size: 13px;
              padding: 2px 8px;
              color: white;
              margin-top: auto;
              margin-bottom: auto;
              margin-left: 4px;
            "
          >
            {{
              (
                (1 -
                  proYear.currency_price.price /
                    (pro.currency_price.price * 12)) *
                100
              ).toFixed(0) + '% OFF'
            }}
          </label>
          <label
            style="
              text-decoration: line-through;
              font-size: 14px;
              margin: auto 8px auto auto;
              color: grey;
            "
          >
            {{
              filters.currency(
                pro.currency_price.price * 12,
                pro.currency_price.currency,
              )
            }}
          </label>
          <label>
            {{
              filters.currency(
                proYear.currency_price.price,
                proYear.currency_price.currency,
              )
            }}
          </label>
        </CLoadingButton>
      </div>
    </CModalBody>
  </CModal>
</template>

<script lang="ts">
import { cilCloudUpload, cilInfo } from '@coreui/icons'
import TimelapseIcon from 'vue-material-design-icons/Timelapse.vue'
import Numeric1Icon from 'vue-material-design-icons/Numeric1.vue'
import AccountMultipleIcon from 'vue-material-design-icons/AccountMultiple.vue'
import MessageTextIcon from 'vue-material-design-icons/MessageText.vue'
import FileDocumentOutlineIcon from 'vue-material-design-icons/FileDocumentOutline.vue'
import { logEvent } from 'firebase/analytics'
import filters from '../../helpers/filters'
import link from '@/assets/text/link'

export default {
  name: 'PurchaseModal',
  components: {
    // icons start
    TimelapseIcon,
    Numeric1Icon,
    AccountMultipleIcon,
    MessageTextIcon,
    FileDocumentOutlineIcon,
    // icons end
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isAnonymous: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      cilCloudUpload,
      cilInfo,
    }
  },
  watch: {
    visible(val) {
      // if (val) {
      //   this.reset_data()
      // }
    },
  },
  data() {
    return {
      pro: null,
      proYear: null,
      proLoading: false,
      proYearLoading: false,
      link,
    }
  },
  emits: ['close'],
  computed: {
    filters() {
      return filters
    },
    isMobile() {
      return screen.width <= 760
    },
    // locale() {
    //   return this.$i18n.locale
    // },
  },
  created() {
    this.fetchData()
  },
  methods: {
    firebaseEvent(eventName) {
      logEvent(this.analytics, eventName)
    },
    async paymentClick(plan) {
      if (this.isAnonymous) {
        this.$router.push('/register')
        return
      }

      if (this.proLoading || this.proYearLoading) return
      let tmpLocale = this.$i18n.locale

      if (tmpLocale.includes('zh')) {
        tmpLocale = 'zh'
      }
      if (plan.uid === 'sub_pro') {
        this.proLoading = true
      } else {
        this.proYearLoading = true
      }
      this.firebaseEvent('wa_purchase_' + plan.uid)
      const url = await this.apiClient.stripeCheckout(
        plan.currency_price.stripe_price_id, // lite
        plan.uid,
        link.domain,
        link.domain,
        tmpLocale,
      )
      window.open(url, '_self')
    },
    fetchData() {
      // print(this.locale)
      this.apiClient.getProducts(this.$i18n.locale).then((res) => {
        this.pro = res.data.find((d) => d.uid === 'sub_pro')
        this.proYear = res.data.find((d) => d.uid === 'sub_pro_yearly')
      })
    },
  },
}
</script>
<style scoped lang="scss">
.pro {
  &__features {
    display: flex;
    margin-bottom: 20px;

    &__icon {
      margin-right: 16px;
    }

    &__title {
    }

    &__subtitle {
      color: grey;
      font-size: 14px;
    }
  }
}

a.purchase-button {
  text-decoration: none;
  font-weight: bold;
}

.purchase-button {
  width: 100%;
  display: flex;

  label:hover {
    cursor: pointer;
  }
}
</style>
